import * as React from "react";
import { useState } from "react";
import { useUserContext } from "../common/UserContext/UserContext";
import { IPasswordResetRequiredParams, StaticRoutePath } from "../Routes";
import { Link, useParams } from "react-router-dom";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { GoldenRatioishPage } from "../common/GoldenRatioishPage/GoldenRatioishPage";
import { Title } from "../common/Title/Title";

export function PasswordResetRequired() {
  const { getPasswordRecoveryCode, recoverPassword } = useUserContext();
  const { email } = useParams<IPasswordResetRequiredParams>();
  const [codeRequested, setCodeRequested] = useState(true);
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordRecovered, setPasswordRecovered] = useState(false);
  const [submitError, setSubmitError] = useState<undefined | string>();

  async function getRecoveryCode() {
    try {
      const success = await getPasswordRecoveryCode(email);
      if (success) {
        setCodeRequested(true);
      }
    } catch (error) {
      setSubmitError(error.message);
    }
  }

  async function recover() {
    try {
      const success = await recoverPassword(email, code, newPassword);
      if (success) {
        setPasswordRecovered(true);
      }
    } catch (error) {
      setSubmitError(error.message);
    }
  }

  return (
    <GoldenRatioishPage>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title>Please reset your account password</Title>
          <br />
          <Typography variant="body1">
            We’ve made some updates to the dashboard and require our users to
            reset their password to have those changes take effect. An email
            with the verification code should soon arrive to your email inbox.
            In case you need assistance please contact us at
            support@upsyshopping.com.
          </Typography>
        </Grid>
        {passwordRecovered ? (
          <>
            <Grid item xs={12}>
              <Typography variant="body1">
                Your password was succesfully reset!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Link to={StaticRoutePath.Login}>Login</Link>
            </Grid>
          </>
        ) : codeRequested ? (
          <>
            <Grid item xs={12}>
              <Typography variant="body1">
                The email address linked to your user account is {email}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {" "}
              <TextField
                label="Code"
                autoComplete="new-password"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              {" "}
              <TextField
                label="New Password"
                type="password"
                autoComplete="new-password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={recover}>
                Set new password
              </Button>
            </Grid>
            {submitError && (
              <Grid item xs={12}>
                <p>{submitError}</p>
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <TextField label="Email" value={email} disabled={true} />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={getRecoveryCode}
              >
                Recover password
              </Button>
            </Grid>
            {submitError && (
              <Grid item xs={12}>
                <p>{submitError}</p>
              </Grid>
            )}
            <Grid item xs={12}>
              <Link to={StaticRoutePath.Login}>Login</Link>
            </Grid>
          </>
        )}
      </Grid>
    </GoldenRatioishPage>
  );
}
