import {
  defaultLanguage,
  defaultOperationMode,
  defaultPlatform,
  IConfiguration,
  IConfigurationCommonBackend,
  IConfigurationCommonFrontend,
  IConfigurationWooCommerceSpecific,
  newConfigurationId,
} from "./Configuration";
import {
  FlowRulesAction,
  flowRulesReducer,
} from "../../Stores/StoreConfiguration/flowRulesReducer";
import {
  readFromLocalStorage,
  writeToLocalStorageDebounced,
} from "../utils/localStorage";
import {
  FAQsAction,
  FAQsReducer,
} from "../../Stores/StoreConfiguration/FAQsReducer";

export const configurationsInitialState: IConfiguration[] = [];
const newConfigurationInitialState = {
  id: newConfigurationId,
  platform: defaultPlatform,
  operationMode: defaultOperationMode,
  language: defaultLanguage,
};

enum ConfigurationActionType {
  SetAll = "SetAll",
  SetCommonBackendConfiguration = "SetCommonBackendConfiguration",
  SetCommonFrontendConfiguration = "SetCommonFrontendConfiguration",
  SetCommonFrontendFlowRules = "SetCommonFrontendFlowRules",
  SetCommonFrontendFAQs = "SetCommonFrontendFAQs",
  SetWooCommerceSpecificConfiguration = "SetWooCommerceSpecificConfiguration",
  ClearTemp = "ClearTemp",
}

export interface SetAllConfigurationsAction {
  type: ConfigurationActionType.SetAll;
  configurations: IConfiguration[];
}

export function setAllConfigurations(
  configurations: IConfiguration[]
): SetAllConfigurationsAction {
  const tempNewConfigurationString =
    readFromLocalStorage(localStorageKeyTempNewConfiguration) || "{}";
  return {
    type: ConfigurationActionType.SetAll,
    configurations: [
      ...configurations,
      {
        ...newConfigurationInitialState,
        ...JSON.parse(tempNewConfigurationString),
      },
    ],
  };
}

export interface SetCommonBackendConfigurationAction {
  type: ConfigurationActionType.SetCommonBackendConfiguration;
  id: string;
  commonBackendConfiguration: Partial<IConfigurationCommonBackend>;
}

export function setCommonBackendConfiguration(
  id: SetCommonBackendConfigurationAction["id"],
  commonBackendConfiguration: SetCommonBackendConfigurationAction["commonBackendConfiguration"]
): SetCommonBackendConfigurationAction {
  return {
    type: ConfigurationActionType.SetCommonBackendConfiguration,
    id,
    commonBackendConfiguration,
  };
}

export interface SetCommonFrontendConfigurationAction {
  type: ConfigurationActionType.SetCommonFrontendConfiguration;
  id: string;
  commonFrontendConfiguration: Partial<IConfigurationCommonFrontend>;
}

export function setCommonFrontendConfiguration(
  id: SetCommonFrontendConfigurationAction["id"],
  commonFrontendConfiguration: SetCommonFrontendConfigurationAction["commonFrontendConfiguration"]
): SetCommonFrontendConfigurationAction {
  return {
    type: ConfigurationActionType.SetCommonFrontendConfiguration,
    id,
    commonFrontendConfiguration,
  };
}

export interface SetCommonFrontendFlowRulesAction {
  type: ConfigurationActionType.SetCommonFrontendFlowRules;
  id: string;
  flowRulesAction: FlowRulesAction;
}

export function setCommonFrontendFlowRules(
  id: SetCommonFrontendFlowRulesAction["id"],
  flowRulesAction: SetCommonFrontendFlowRulesAction["flowRulesAction"]
): SetCommonFrontendFlowRulesAction {
  return {
    type: ConfigurationActionType.SetCommonFrontendFlowRules,
    id,
    flowRulesAction,
  };
}

export interface SetCommonFrontendFAQsAction {
  type: ConfigurationActionType.SetCommonFrontendFAQs;
  id: string;
  FAQsAction: FAQsAction;
}

export function setCommonFrontendFAQs(
  id: SetCommonFrontendFlowRulesAction["id"],
  FAQsAction: SetCommonFrontendFAQsAction["FAQsAction"]
): SetCommonFrontendFAQsAction {
  return {
    type: ConfigurationActionType.SetCommonFrontendFAQs,
    id,
    FAQsAction,
  };
}

export interface SetWooCommerceSpecificConfigurationAction {
  type: ConfigurationActionType.SetWooCommerceSpecificConfiguration;
  id: string;
  wooCommerceSpecificConfiguration: Partial<IConfigurationWooCommerceSpecific>;
}

export function setWooCommerceSpecificConfiguration(
  id: SetWooCommerceSpecificConfigurationAction["id"],
  wooCommerceSpecificConfiguration: SetWooCommerceSpecificConfigurationAction["wooCommerceSpecificConfiguration"]
): SetWooCommerceSpecificConfigurationAction {
  return {
    type: ConfigurationActionType.SetWooCommerceSpecificConfiguration,
    id,
    wooCommerceSpecificConfiguration,
  };
}

export interface ClearTempConfigurationAction {
  type: ConfigurationActionType.ClearTemp;
}

export function clearTempConfiguration(): ClearTempConfigurationAction {
  return {
    type: ConfigurationActionType.ClearTemp,
  };
}

export type ConfigurationAction =
  | SetAllConfigurationsAction
  | SetCommonBackendConfigurationAction
  | SetCommonFrontendConfigurationAction
  | SetCommonFrontendFlowRulesAction
  | SetCommonFrontendFAQsAction
  | SetWooCommerceSpecificConfigurationAction
  | ClearTempConfigurationAction;

export const localStorageKeyTempNewConfiguration = "tempNewConfiguration";

export function localStorageConfigurationsReducer(
  state: IConfiguration[] = configurationsInitialState,
  action: ConfigurationAction
) {
  const newState = configurationsReducer(state, action);
  const newConfigurationIndex = newState.findIndex(
    (value) => value.id === newConfigurationId
  );
  if (0 <= newConfigurationIndex) {
    writeToLocalStorageDebounced(
      localStorageKeyTempNewConfiguration,
      JSON.stringify(newState[newConfigurationIndex])
    );
  }
  return newState;
}

export function configurationsReducer(
  state: IConfiguration[] = configurationsInitialState,
  action: ConfigurationAction
): IConfiguration[] {
  if (action.type === ConfigurationActionType.SetAll) {
    return setAllConfigurationsReducer(state, action);
  } else if (
    action.type === ConfigurationActionType.SetCommonBackendConfiguration
  ) {
    return commonBackendConfigurationReducer(state, action);
  } else if (
    action.type === ConfigurationActionType.SetCommonFrontendConfiguration
  ) {
    return commonFrontendConfigurationReducer(state, action);
  } else if (
    action.type === ConfigurationActionType.SetCommonFrontendFlowRules
  ) {
    return commonFrontendFlowRulesReducer(state, action);
  } else if (action.type === ConfigurationActionType.SetCommonFrontendFAQs) {
    return commonFrontendFAQsReducer(state, action);
  } else if (
    action.type === ConfigurationActionType.SetWooCommerceSpecificConfiguration
  ) {
    return wooCommerceSpecificConfigurationReducer(state, action);
  } else if (action.type === ConfigurationActionType.ClearTemp) {
    return clearTempConfigurationReducer(state, action);
  }

  return state;
}

function setAllConfigurationsReducer(
  state: IConfiguration[],
  action: SetAllConfigurationsAction
) {
  return action.configurations;
}

function commonBackendConfigurationReducer(
  state: IConfiguration[],
  action: SetCommonBackendConfigurationAction
) {
  return state.map((configuration) => {
    if (configuration.id === action.id) {
      return {
        ...configuration,
        ...action.commonBackendConfiguration,
      };
    } else {
      return configuration;
    }
  }) as IConfiguration[];
}

function commonFrontendConfigurationReducer(
  state: IConfiguration[],
  action: SetCommonFrontendConfigurationAction
) {
  return state.map((configuration) => {
    if (configuration.id === action.id) {
      return {
        ...configuration,
        ...action.commonFrontendConfiguration,
      };
    } else {
      return configuration;
    }
  }) as IConfiguration[];
}

function commonFrontendFlowRulesReducer(
  state: IConfiguration[],
  action: SetCommonFrontendFlowRulesAction
) {
  return state.map((configuration) => {
    if (configuration.id === action.id) {
      return {
        ...configuration,
        flows: flowRulesReducer(configuration.flows, action.flowRulesAction),
      };
    } else {
      return configuration;
    }
  }) as IConfiguration[];
}

function commonFrontendFAQsReducer(
  state: IConfiguration[],
  action: SetCommonFrontendFAQsAction
) {
  return state.map((configuration) => {
    if (configuration.id === action.id) {
      return {
        ...configuration,
        customQnA: FAQsReducer(configuration.customQnA, action.FAQsAction),
      };
    } else {
      return configuration;
    }
  }) as IConfiguration[];
}

function wooCommerceSpecificConfigurationReducer(
  state: IConfiguration[],
  action: SetWooCommerceSpecificConfigurationAction
) {
  return state.map((configuration) => {
    if (configuration.id === action.id) {
      return {
        ...configuration,
        ...action.wooCommerceSpecificConfiguration,
      };
    } else {
      return configuration;
    }
  }) as IConfiguration[];
}

function clearTempConfigurationReducer(
  state: IConfiguration[],
  _action: ClearTempConfigurationAction
) {
  return state.map((configuration) =>
    configuration.id === newConfigurationId
      ? newConfigurationInitialState
      : configuration
  );
}
