import * as React from "react";
import { ReactNode } from "react";
import { DesktopNavigation, MobileNavigation } from "../Navigation/Navigation";
import { Box, Grid, Hidden } from "@material-ui/core";
import { FullPageHeightGrid } from "./FullPageHeightGrid";
import { createUseClasses } from "../Theme/createUseClasses";

export function Page({
  title,
  internalNavigation,
  children,
}: {
  title?: ReactNode;
  internalNavigation?: ReactNode;
  children?: ReactNode;
}) {
  const classes = useClasses();
  return (
    <FullPageHeightGrid container>
      <Hidden smDown>
        <Grid item md={3} lg={2} className={classes.navigationContainer}>
          <DesktopNavigation />
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <MobileNavigation />
      </Hidden>
      <Grid
        item
        xs={12}
        md={9}
        lg={10}
        className={classes.pageHeaderAndContent}
      >
        {title && <Box m={2}>{title}</Box>}
        {internalNavigation && (
          <Box m={2} className={classes.noMarginTop}>
            {internalNavigation}
          </Box>
        )}
        {children && (
          <Box
            m={2}
            className={`${classes.pageContent} ${classes.noMarginTop}`}
          >
            {children}
          </Box>
        )}
      </Grid>
    </FullPageHeightGrid>
  );
}

const useClasses = createUseClasses((theme) => ({
  noMarginTop: {
    marginTop: 0,
  },
  navigationContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  pageHeaderAndContent: {
    maxHeight: "100vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    padding: "30px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "64px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "56px",
    },
  },
  pageContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));
