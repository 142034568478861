import * as React from "react";
import { useUserContext } from "../common/UserContext/UserContext";
import { StaticRoutePath } from "../Routes";
import { Redirect, useHistory } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Loader } from "../common/Loader/Loader";
import { GoldenRatioishPage } from "../common/GoldenRatioishPage/GoldenRatioishPage";
import { Title } from "../common/Title/Title";

export function Lobby() {
  const history = useHistory();
  const { loading, user } = useUserContext();
  const shouldShowConfirmEmailView = user && !user.verified;

  if (loading) {
    return <Loader />;
  }
  if (shouldShowConfirmEmailView) {
    return <Redirect to={StaticRoutePath.ConfirmEmail} />;
  }
  if (user) {
    return <Redirect to={StaticRoutePath.Stores} />;
  }

  return (
    <GoldenRatioishPage>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box m={2}>
            <Title>Welcome!</Title>
            <Typography variant="subtitle1">
              Please register or log in.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push(StaticRoutePath.Register)}
          >
            Register
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => history.push(StaticRoutePath.Login)}
          >
            Login
          </Button>
        </Grid>
      </Grid>
    </GoldenRatioishPage>
  );
}
