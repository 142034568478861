import { Button, CircularProgress } from "@material-ui/core";
import { createUseClasses } from "../Theme/createUseClasses";
import { ButtonProps } from "@material-ui/core/Button/Button";

export const LoadingButton = ({
  loading,
  loadingText = "Loading",
  buttonText,
  progress,
  ...buttonProps
}: {
  loading: boolean;
  loadingText?: string;
  buttonText: string;
  progress?: number;
} & Omit<ButtonProps, "children">) => {
  const classes = useClasses();

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={loading}
      {...buttonProps}
    >
      {loading ? loadingText : buttonText}
      {loading && (
        <CircularProgress
          className={classes.spinner}
          size={20}
          variant={progress ? "determinate" : "indeterminate"}
          value={progress}
        />
      )}
    </Button>
  );
};

const useClasses = createUseClasses(() => ({
  spinner: {
    marginLeft: 10,
  },
}));
