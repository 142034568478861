import {
  defaultAvatar,
  defaultButtonColor,
  defaultLanguage,
  defaultOperationMode,
  IBackendConfigurationCommon,
  IConfiguration,
  IWooCommerceConfiguration,
  OperationMode,
  OperationModeFE,
  WooCommerceApiVersion,
} from "./Configuration";
import {
  IConfigurationCommonFrontendDynamoDB,
  UpsertShopConfigurationRequest,
} from "../Client/BackendResponse";

interface UpsertShopConfigurationRequestCommon {
  backendConfiguration: IBackendConfigurationCommon;
  frontendConfiguration: IConfigurationCommonFrontendDynamoDB;
}

const transformOperationMode = (operationMode: string) => {
  return operationMode === OperationMode.Production
    ? OperationModeFE.Production
    : operationMode === OperationMode.Test
    ? OperationModeFE.Test
    : OperationModeFE.Production;
};

export const transformCommonProperties = (
  shopConfiguration: IConfiguration
): UpsertShopConfigurationRequestCommon => ({
  backendConfiguration: {
    id: shopConfiguration.storeName || "",
    tenantId: shopConfiguration.storeName || "",
    type: shopConfiguration.platform,
    urlBase: shopConfiguration.storeUrl || "",
  },
  frontendConfiguration: {
    testMode: transformOperationMode(
      shopConfiguration.operationMode ?? defaultOperationMode
    ),
    language: shopConfiguration.language ?? defaultLanguage,
    avatar: shopConfiguration.avatar ?? defaultAvatar,
    buttonColor: shopConfiguration.buttonColor ?? defaultButtonColor,
    widgetToLeft: shopConfiguration.widgetToLeft as boolean,
    flows: shopConfiguration.flows ?? [],
    customQnA: shopConfiguration.customQnA ?? [],
  },
});

const transformToWooCommerceConfiguration = (
  shopConfiguration: IWooCommerceConfiguration
): UpsertShopConfigurationRequest => {
  const commonConfiguration = transformCommonProperties(shopConfiguration);
  return {
    ...commonConfiguration,
    backendConfiguration: {
      ...commonConfiguration.backendConfiguration,
      productUrlPath: `wp-json/wc/${
        shopConfiguration.apiVersion || WooCommerceApiVersion.v3
      }/products`,
      categoryUrlPath: `wp-json/wc/${
        shopConfiguration.apiVersion || WooCommerceApiVersion.v3
      }/products/categories`,
      consumerKey: shopConfiguration.key,
      consumerSecret: shopConfiguration.secret,
      authStrategy: shopConfiguration.authStrategy,
      language: shopConfiguration.woocommerceLanguage,
    },
  };
};

const transformToGenericConfiguration = (
  shopConfiguration: IWooCommerceConfiguration
): UpsertShopConfigurationRequest => {
  const commonConfiguration = transformCommonProperties(shopConfiguration);
  return {
    ...commonConfiguration,
    backendConfiguration: {
      ...commonConfiguration.backendConfiguration,
    },
  };
};

interface TransformationSpec {
  transform(shopConfiguration: IConfiguration): UpsertShopConfigurationRequest;
}

interface TransformationSpecByPlatformType {
  [key: string]: TransformationSpec;
}

export const backendTransformationByType: TransformationSpecByPlatformType = {
  woocommerce: { transform: transformToWooCommerceConfiguration },
  default: { transform: transformToGenericConfiguration },
};
