import React, { useState } from "react";

function usePagination(data: any[], itemsPerPage = 20) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  //Get the paginated data
  function getPaginatedData() {
    return data.slice(startIndex, endIndex);
  }

  function nextPage() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prevPage() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  //Jump to a specific page
  function jumpToPage(page: number) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }

  return {
    nextPage,
    prevPage,
    jumpToPage,
    getPaginatedData,
    maxPage,
    itemsPerPage,
    currentPage,
    startIndex,
    endIndex,
  };
}

export default usePagination;
