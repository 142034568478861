import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { Dashboard } from "./Stores/Dashboard/Dashboard";
import { Lobby } from "./Lobby/Lobby";
import { useUserContext } from "./common/UserContext/UserContext";
import { Loader } from "./common/Loader/Loader";
import { Login } from "./Login/Login";
import { Register } from "./Register/Register";
import { ConfirmEmail } from "./Register/ConfirmEmail";
import { ForgotPassword } from "./ForgotPassword/ForgotPassword";
import { Account } from "./Account/Account";
import { Stores } from "./Stores/Stores";
import { StoreConfiguration } from "./Stores/StoreConfiguration/StoreConfiguration";
import { Metrics } from "./Stores/Metrics/Metrics";
import { NotFound } from "./404/NotFound";
import { PasswordResetRequired } from "./PasswordResetRequired/PasswordResetRequired";

export enum StaticRoutePath {
  Root = "/",
  Login = "/login",
  Register = "/register",
  ConfirmEmail = "/confirm-email",
  ForgotPassword = "/forgot-password",
  PasswordResetRequired = "/password-reset-required",
  Stores = "/stores",
  Account = "/account",
  NotFound = "*",
}

export const DynamicRoutePath = {
  Store: (id: string) => `${StaticRoutePath.Stores}/${id}`,
  StoreConfiguration: (id: string) =>
    `${StaticRoutePath.Stores}/${id}/configuration`,
  StoreDashboard: (id: string) => `${StaticRoutePath.Stores}/${id}/dashboard`,
  StoreMetrics: (id: string) => `${StaticRoutePath.Stores}/${id}/metrics`,
  PasswordResetRequired: (email: string) =>
    `${StaticRoutePath.PasswordResetRequired}/${email}`,
};

export interface IStoreRouteParams {
  id: string;
}
export interface IPasswordResetRequiredParams {
  email: string;
}
export function Routes() {
  return (
    <Switch>
      <Route exact path={StaticRoutePath.Root}>
        <Lobby />
      </Route>
      <Route path={StaticRoutePath.Login}>
        <Login />
      </Route>
      <Route path={StaticRoutePath.Register}>
        <Register />
      </Route>
      <Route path={StaticRoutePath.ConfirmEmail}>
        <ConfirmEmail />
      </Route>
      <Route path={StaticRoutePath.ForgotPassword}>
        <ForgotPassword />
      </Route>
      <Route exact path={DynamicRoutePath.PasswordResetRequired(":email")}>
        <PasswordResetRequired />
      </Route>
      <PrivateRoute exact path={StaticRoutePath.Stores}>
        <Stores />
      </PrivateRoute>
      <PrivateRoute exact path={DynamicRoutePath.Store(":id")}>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path={DynamicRoutePath.StoreDashboard(":id")}>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path={DynamicRoutePath.StoreMetrics(":id")}>
        <Metrics />
      </PrivateRoute>
      <PrivateRoute path={DynamicRoutePath.StoreConfiguration(":id")}>
        <StoreConfiguration />
      </PrivateRoute>
      <PrivateRoute path={StaticRoutePath.Account}>
        <Account />
      </PrivateRoute>
      <Route path={StaticRoutePath.NotFound}>
        <NotFound />
      </Route>
    </Switch>
  );
}

function PrivateRoute({ children, ...rest }: any) {
  const { loading, user } = useUserContext();
  return loading ? (
    <Loader />
  ) : user && user.verified ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Login />
  );
}
