import { Grid, styled } from "@material-ui/core";
import * as React from "react";
import { ReactNode } from "react";
import { FullPageHeightGrid } from "../Page/FullPageHeightGrid";
import { CenteringContainer } from "../CenteringContainer/CenteringContainer";
import { LogoUpsyShoppingHelper } from "../SVG/Component/LogoUpsyShoppingHelper";
import { createUseClasses } from "../Theme/createUseClasses";

export function GoldenRatioishPage({
  children,
}: {
  children: NonNullable<ReactNode>;
}) {
  const classes = useClasses();

  return (
    <FullPageHeightGrid container spacing={2}>
      <GradientBackgroundSupportContent
        item
        sm={5}
        xs={12}
        className={classes.logoContent}
      >
        <CenteringContainer>
          <LogoUpsyShoppingHelper variant="dark" width="50%" />
        </CenteringContainer>
      </GradientBackgroundSupportContent>
      <Grid item sm={7} xs={12} className={classes.fullHeightContainer}>
        <CenteringContainer>{children}</CenteringContainer>
      </Grid>
    </FullPageHeightGrid>
  );
}

const GradientBackgroundSupportContent = styled(Grid)({
  background:
    "linear-gradient(169deg, rgba(215,213,255,1) 0%, rgba(178,200,255,1) 19%, rgba(253,247,234,1) 50%, rgba(252,243,231,1) 62%, rgba(241,180,183,1) 86%, rgba(226,97,121,1) 100%)",
});

const useClasses = createUseClasses((theme) => ({
  logoContent: {
    [theme.breakpoints.only("xs")]: {
      height: "38vh",
    },
  },
  fullHeightContainer: {
    height: "100%",
    [theme.breakpoints.only("xs")]: {
      minHeight: "62vh",
    },
  },
}));
