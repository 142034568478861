import {
  categoriesUIPopupOption,
  createDefaultFlowRuleUIFaqButton,
  createDefaultFlowRuleUIInitialButton,
  FlowRuleType,
  IFlowRule,
} from "./flowRules";
import { PopupOptionType } from "./flowRules";

export const flowRulesReducerInitialStateNew: IFlowRule[] = [
  getDefaultThankYouRule(),
  getDefaultCheckoutRule(),
  getDefaultCartRule(),
  getDefaultProductRule(),
  getDefaultCategoryRule(),
  getDefaultGeneralRule(),
];

function getDefaultThankYouRule(): IFlowRule {
  return {
    trigger: {
      type: FlowRuleType.Url,
      value: "/order-received/",
      isPurchase: true,
    },
    buttons: {
      initial: [createDefaultFlowRuleUIInitialButton()],
      expanded: [
        {
          type: PopupOptionType.faq,
          text: "Lisätietoja toimitus- ja palautustavoista",
        },
        {
          type: PopupOptionType.searchResults,
          text: "Etsi muita tuotteita",
        },
      ],
    },
  };
}

function getDefaultCheckoutRule(): IFlowRule {
  return {
    trigger: {
      type: FlowRuleType.Url,
      value: "/kassa/",
    },
    buttons: {
      initial: [createDefaultFlowRuleUIInitialButton()],
      expanded: [
        {
          type: PopupOptionType.faq,
          text: "Lisätietoja toimitus- ja palautustavoista",
        },
        {
          type: PopupOptionType.searchResults,
          text: "Etsi muita tuotteita",
        },
      ],
    },
  };
}

function getDefaultCartRule(): IFlowRule {
  return {
    trigger: {
      type: FlowRuleType.Url,
      value: "/ostoskori",
    },
    buttons: {
      initial: [
        createDefaultFlowRuleUIInitialButton(
          "Haluatko lisätietoja tilauksen tekemisestä tai maksu- ja toimitustavoista?"
        ),
      ],
      expanded: [
        {
          type: PopupOptionType.relevantProducts,
          text: "Näytä yhteensopivia tuotteita",
        },
        {
          type: PopupOptionType.faq,
          text: "Lisätietoja toimitus- ja palautustavoista",
        },
      ],
    },
  };
}

function getDefaultProductRule(): IFlowRule {
  return {
    trigger: {
      type: FlowRuleType.ClassSelector,
      value: ".single-product",
    },
    buttons: {
      initial: [
        createDefaultFlowRuleUIInitialButton(
          "Haluatko lisätietoja tästä tuotteesta tai vaihtoehtoisia tuotesuosituksia?"
        ),
      ],
      expanded: [
        {
          type: PopupOptionType.productFaq,
          text: "Lisätietoja tuotteesta",
        },
        {
          type: PopupOptionType.relevantProducts,
          text: "Katso vaihtoehtoiset tuotteet",
        },
        {
          type: PopupOptionType.faq,
          text: "Lisätietoja toimitus- ja palautustavoista",
        },
        {
          type: PopupOptionType.addToCart,
          text: "+ Lisää ostoskoriin",
        },
      ],
    },
  };
}

function getDefaultCategoryRule(): IFlowRule {
  return {
    trigger: {
      type: FlowRuleType.UpsySelector,
      value: "category",
    },
    buttons: {
      initial: [
        createDefaultFlowRuleUIInitialButton(
          "Moikka! Autan sinua löytämään sopivan tuotteen tästä ryhmästä."
        ),
      ],
      expanded: [
        createDefaultFlowRuleUIFaqButton(),
        {
          type: PopupOptionType.searchResults,
          text: "Etsi tuote",
        },
      ],
    },
  };
}

function getDefaultGeneralRule(): IFlowRule {
  return {
    trigger: {
      type: FlowRuleType.Url,
      value: "/",
    },
    buttons: {
      initial: [createDefaultFlowRuleUIInitialButton()],
      expanded: [
        {
          type: PopupOptionType.searchResults,
          text: "Etsi tuote",
        },
        {
          type: categoriesUIPopupOption,
        },
        {
          type: PopupOptionType.faq,
          text: "Lisätietoja toimitus- ja palautustavoista",
        },
      ],
    },
  };
}
