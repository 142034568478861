import * as React from "react";
import { Box, LinearProgress, Typography } from "@material-ui/core";
import { GoldenRatioishPage } from "../GoldenRatioishPage/GoldenRatioishPage";

export function Loader() {
  return (
    <GoldenRatioishPage>
      <LinearProgress />
      <Box m={1}>
        <Typography variant="subtitle2">Loading...</Typography>
      </Box>
    </GoldenRatioishPage>
  );
}
