export interface IFAQ {
  question: string;
  answer: string;
  type: FAQType;
  originalIndex?: number;
}

export enum FAQType {
  Text = "Text",
  Url = "Url",
}

export function createDefaultFAQ(): IFAQ {
  return createDefaultTextFAQ();
}

export function createDefaultTextFAQ(): IFAQ {
  return {
    question: "",
    answer: "",
    type: FAQType.Text,
  };
}

export function createDefaultUrlFAQ(): IFAQ {
  return {
    question: "",
    answer: "",
    type: FAQType.Url,
  };
}

export function getFAQTypeName(type: FAQType) {
  if (type === FAQType.Url) {
    return "Url";
  } else if (type === FAQType.Text) {
    return "Answer";
  } else {
    return "Answer";
  }
}
