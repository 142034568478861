import {
  defaultAvatar,
  defaultButtonColor,
  defaultLanguage,
  defaultOperationMode,
  DisplayLanguage,
  IConfiguration,
  IConfigurationCommonFrontend,
  Language,
  OperationMode,
} from "../../common/ConfigurationsContext/Configuration";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { UpsyPreview } from "./UpsyPreview";
import { ConfigurationWizardPageProps } from "./configurationWizardPage";
import {
  ConfigurationInputContainer,
  ConfigurationInputGroupContainer,
} from "./ConfigurationInputContainer";
import { CompactPicker } from "react-color";
import { Title, TitleVariant } from "../../common/Title/Title";
import { CustomHtmlTooltip } from "../../common/Tooltip/Tooltip";
import { createUseClasses } from "../../common/Theme/createUseClasses";

export function CommonFrontendConfiguration({
  id,
}: ConfigurationWizardPageProps) {
  const { configuration, setCommonFrontendConfiguration } =
    useConfiguration(id);
  const { operationMode, language, avatar, buttonColor, widgetToLeft } =
    getPartialCommonFrontendConfiguration(configuration);
  const classes = useClasses();
  return (
    <>
      <Title variant={TitleVariant.Secondary}>Styles Settings</Title>
      <ConfigurationInputGroupContainer>
        <Grid item xs={6}>
          <CustomHtmlTooltip title="Select whether this configuration is used in production environment or not.">
            <FormControl>
              <InputLabel>Operation mode</InputLabel>
              <Select
                value={operationMode}
                onChange={(event) =>
                  setCommonFrontendConfiguration({
                    operationMode: event.target.value as OperationMode,
                  })
                }
              >
                <MenuItem value={OperationMode.Test}>
                  {OperationMode.Test}
                </MenuItem>
                <MenuItem value={OperationMode.Production}>
                  {OperationMode.Production}
                </MenuItem>
              </Select>
            </FormControl>
          </CustomHtmlTooltip>
        </Grid>
        <Grid item xs={6}>
          <CustomHtmlTooltip title="Select upsy UI language.">
            <FormControl>
              <InputLabel>Language</InputLabel>
              <Select
                disabled={true}
                value={language}
                onChange={(event) =>
                  setCommonFrontendConfiguration({
                    language: event.target.value as Language,
                  })
                }
              >
                <MenuItem value={Language.Finnish}>
                  {DisplayLanguage.fi}
                </MenuItem>
                <MenuItem value={Language.English}>
                  {DisplayLanguage.en}
                </MenuItem>
                <MenuItem value={Language.Swedish}>
                  {DisplayLanguage.se}
                </MenuItem>
              </Select>
            </FormControl>
          </CustomHtmlTooltip>
        </Grid>
        <Grid item xs={12}>
          <CustomHtmlTooltip title="Select button color for upsy widget (see preview in the bottom corner).">
            <div>
              <Typography variant="subtitle1">Button color</Typography>
              <CompactPicker
                styles={{ default: { compact: { width: "100%" } } }}
                color={buttonColor}
                onChangeComplete={(color) =>
                  setCommonFrontendConfiguration({
                    buttonColor: color.hex,
                  })
                }
              />
            </div>
          </CustomHtmlTooltip>
        </Grid>
        <Grid item xs={12}>
          <CustomHtmlTooltip title="Provide a web address to an image to be used as upsy widget avatar (see preview in the bottom corner).">
            <TextField
              label="Avatar URL"
              value={avatar}
              onChange={(event) =>
                setCommonFrontendConfiguration({ avatar: event.target.value })
              }
            />
          </CustomHtmlTooltip>
          <Box my={2}>
            <CustomHtmlTooltip title="By default Upsy widget will appear on the bottom right side inside your store. Here, you can change the Upsy widget to appear on the bottom left side instead.">
              <FormControl>
                <InputLabel>Widget Direction</InputLabel>
                <Select
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                  value={widgetToLeft}
                  onChange={(event) =>
                    setCommonFrontendConfiguration({
                      widgetToLeft:
                        (event.target.value as string)
                          ?.toLowerCase()
                          ?.trim() === "true"
                          ? true
                          : false,
                    })
                  }
                >
                  <MenuItem value="true" selected={widgetToLeft}>
                    Bottom left side
                  </MenuItem>
                  <MenuItem value="false" selected={!widgetToLeft}>
                    Bottom right side
                  </MenuItem>
                </Select>
              </FormControl>
            </CustomHtmlTooltip>
          </Box>
        </Grid>
        <div className={classes.root}>
          <UpsyPreview
            avatar={avatar}
            widgetToLeft={widgetToLeft}
            buttonColor={buttonColor}
            buttonTexts={["Etsi tuote", "Kysy myyjältä"]}
          />
        </div>
      </ConfigurationInputGroupContainer>
    </>
  );
}

function getPartialCommonFrontendConfiguration(
  configuration?: IConfiguration
): Omit<IConfigurationCommonFrontend, "flows" | "customQnA"> {
  return {
    operationMode: configuration?.operationMode ?? defaultOperationMode,
    language: configuration?.language ?? defaultLanguage,
    widgetToLeft: (configuration?.widgetToLeft ?? false) as boolean,
    avatar:
      configuration?.avatar && configuration?.avatar !== ""
        ? configuration?.avatar
        : defaultAvatar,
    buttonColor:
      configuration?.buttonColor && configuration?.buttonColor !== ""
        ? configuration?.buttonColor
        : defaultButtonColor,
  };
}

const useClasses = createUseClasses((theme) => ({
  root: {
    width: "100%",
    display: "block",
    background: "rgb(255,255,255)",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    padding: "0.7rem",
    borderRadius: "0.5rem",
  },
}));
