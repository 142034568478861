import {
  categoriesUIPopupOption,
  createDefaultFlowRuleUI,
  createDefaultFlowRuleUIButton,
  FlowRuleType,
  IFlowRule,
  IFlowRuleButtonCommon,
  IFlowRuleButtonUrl,
  PopupOptionType,
  UIPopupOptionType,
} from "./flowRules";
import { flowRulesReducerInitialStateNew } from "./flowRulesReducerInitialStateNew";

export enum FlowRulesActionType {
  Init = "Init",
  SetTriggerType = "SetTriggerType",
  SetTriggerValue = "SetTriggerValue",
  SetPopupText = "SetPopupText",
  SetButtonType = "SetButtonType",
  SetButtonText = "SetButtonText",
  SetButtonUrl = "SetButtonUrl",
  EnablePurchase = "EnablePurchase",
  AddButton = "AddButton",
  RemoveButton = "RemoveButton",
  AddRule = "AddRule",
  RemoveRule = "RemoveRule",
}

interface InitAction {
  type: FlowRulesActionType.Init;
}

export function init(): InitAction {
  return {
    type: FlowRulesActionType.Init,
  };
}

interface SetTriggerTypeAction {
  type: FlowRulesActionType.SetTriggerType;
  index: number;
  triggerType: FlowRuleType;
}

export function setTriggerType(
  index: number,
  triggerType: FlowRuleType
): SetTriggerTypeAction {
  return {
    type: FlowRulesActionType.SetTriggerType,
    index,
    triggerType,
  };
}

interface SetTriggerValueAction {
  type: FlowRulesActionType.SetTriggerValue;
  index: number;
  value: string;
}

export function setTriggerValue(
  index: number,
  value: string
): SetTriggerValueAction {
  return {
    type: FlowRulesActionType.SetTriggerValue,
    index,
    value,
  };
}

interface SetPopupTextAction {
  type: FlowRulesActionType.SetPopupText;
  index: number;
  text: string;
}

export function setPopupText(index: number, text: string): SetPopupTextAction {
  return {
    type: FlowRulesActionType.SetPopupText,
    index,
    text,
  };
}

interface SetButtonTypeAction {
  type: FlowRulesActionType.SetButtonType;
  index: number;
  buttonIndex: number;
  buttonType: UIPopupOptionType;
}

export function setButtonType(
  index: number,
  buttonType: UIPopupOptionType,
  buttonIndex: number
): SetButtonTypeAction {
  return {
    type: FlowRulesActionType.SetButtonType,
    index,
    buttonIndex,
    buttonType,
  };
}

interface SetButtonTextAction {
  type: FlowRulesActionType.SetButtonText;
  index: number;
  buttonIndex: number;
  text: string;
}

export function setButtonText(
  index: number,
  text: string,
  buttonIndex: number
): SetButtonTextAction {
  return {
    type: FlowRulesActionType.SetButtonText,
    index,
    buttonIndex,
    text,
  };
}

interface SetButtonUrlAction {
  type: FlowRulesActionType.SetButtonUrl;
  index: number;
  buttonIndex: number;
  url: string;
}

export function setButtonUrl(
  index: number,
  url: string,
  buttonIndex: number
): SetButtonUrlAction {
  return {
    type: FlowRulesActionType.SetButtonUrl,
    index,
    buttonIndex,
    url,
  };
}

interface EnablePurchaseAction {
  type: FlowRulesActionType.EnablePurchase;
  index: number;
  enabled: boolean;
}

export function enablePurchase(
  index: number,
  enabled: boolean
): EnablePurchaseAction {
  return {
    type: FlowRulesActionType.EnablePurchase,
    index,
    enabled,
  };
}

interface AddButtonAction {
  type: FlowRulesActionType.AddButton;
  index: number;
}

export function addButton(index: number): AddButtonAction {
  return {
    type: FlowRulesActionType.AddButton,
    index,
  };
}

interface RemoveButtonAction {
  type: FlowRulesActionType.RemoveButton;
  index: number;
  buttonIndex: number;
}

export function removeButton(
  index: number,
  buttonIndex: number
): RemoveButtonAction {
  return {
    type: FlowRulesActionType.RemoveButton,
    index,
    buttonIndex,
  };
}

interface AddRuleAction {
  type: FlowRulesActionType.AddRule;
}

export function addRule(): AddRuleAction {
  return {
    type: FlowRulesActionType.AddRule,
  };
}

interface RemoveRuleAction {
  type: FlowRulesActionType.RemoveRule;
  index: number;
}

export function removeRule(index: number): RemoveRuleAction {
  return {
    type: FlowRulesActionType.RemoveRule,
    index,
  };
}

export type FlowRulesAction =
  | InitAction
  | SetTriggerTypeAction
  | SetTriggerValueAction
  | SetPopupTextAction
  | SetButtonTypeAction
  | SetButtonTextAction
  | SetButtonUrlAction
  | EnablePurchaseAction
  | AddButtonAction
  | RemoveButtonAction
  | AddRuleAction
  | RemoveRuleAction;

export function flowRulesReducer(
  state: IFlowRule[] = flowRulesReducerInitialStateNew,
  action: FlowRulesAction
): IFlowRule[] {
  switch (action.type) {
    case FlowRulesActionType.SetTriggerType: {
      return state.map((rule, index) =>
        index === action.index
          ? {
              ...rule,
              trigger: {
                ...rule.trigger,
                type: action.triggerType,
              },
            }
          : rule
      );
    }
    case FlowRulesActionType.SetTriggerValue: {
      return state.map((rule, index) =>
        index === action.index
          ? {
              ...rule,
              trigger: {
                ...rule.trigger,
                value: action.value,
              },
            }
          : rule
      );
    }
    case FlowRulesActionType.SetPopupText: {
      return state.map((rule, index) =>
        index === action.index
          ? {
              ...rule,
              buttons: {
                ...rule.buttons,
                initial: [
                  {
                    type: PopupOptionType.faq,
                    text: action.text,
                  },
                ],
              },
            }
          : rule
      );
    }
    case FlowRulesActionType.SetButtonType: {
      return state.map((rule, index) =>
        index === action.index
          ? {
              ...rule,
              buttons: {
                ...rule.buttons,
                expanded: rule.buttons.expanded.map((button, buttonIndex) => {
                  if (buttonIndex !== action.buttonIndex) {
                    return button;
                  }
                  if (action.buttonType === categoriesUIPopupOption) {
                    return {
                      type: categoriesUIPopupOption,
                    };
                  } else {
                    return {
                      text: (button as IFlowRuleButtonCommon)?.text || "",
                      url: (button as IFlowRuleButtonUrl)?.url || "",
                      type: action.buttonType,
                    };
                  }
                }),
              },
            }
          : rule
      );
    }
    case FlowRulesActionType.SetButtonText: {
      return state.map((rule, index) =>
        index === action.index
          ? {
              ...rule,
              buttons: {
                ...rule.buttons,
                expanded: rule.buttons.expanded.map((button, buttonIndex) => {
                  if (buttonIndex !== action.buttonIndex) {
                    return button;
                  }
                  return {
                    ...button,
                    text: action.text,
                  };
                }),
              },
            }
          : rule
      );
    }
    case FlowRulesActionType.SetButtonUrl: {
      return state.map((rule, index) =>
        index === action.index
          ? {
              ...rule,
              buttons: {
                ...rule.buttons,
                expanded: rule.buttons.expanded.map((button, buttonIndex) => {
                  if (buttonIndex !== action.buttonIndex) {
                    return button;
                  }
                  return {
                    ...button,
                    url: action.url,
                  };
                }),
              },
            }
          : rule
      );
    }
    case FlowRulesActionType.EnablePurchase: {
      return state.map((rule, index) => {
        if (index !== action.index) {
          return rule;
        }
        const newRule: IFlowRule = {
          ...rule,
          trigger: {
            type: rule.trigger.type,
            value: rule.trigger.value,
          },
        };
        if (action.enabled) {
          newRule.trigger.isPurchase = true;
        }
        return newRule;
      });
    }
    case FlowRulesActionType.AddButton: {
      return state.map((rule, index) =>
        index === action.index
          ? {
              ...rule,
              buttons: {
                ...rule.buttons,
                expanded: [
                  ...rule.buttons.expanded,
                  createDefaultFlowRuleUIButton(),
                ],
              },
            }
          : rule
      );
    }
    case FlowRulesActionType.RemoveButton: {
      return state.map((rule, index) =>
        index === action.index
          ? {
              ...rule,
              buttons: {
                ...rule.buttons,
                expanded: rule.buttons.expanded.filter(
                  (button, index) => index !== action.buttonIndex
                ),
              },
            }
          : rule
      );
    }
    case FlowRulesActionType.AddRule: {
      return [...state, createDefaultFlowRuleUI()];
    }
    case FlowRulesActionType.RemoveRule: {
      return state.filter((rule, index) => index !== action.index);
    }
    case FlowRulesActionType.Init:
    default:
      return state;
  }
}
