export function getStartDateFilter(date: Date) {
  return `${getBasicDateString(date)} 00:00`;
}

export function getEndDateFilter(date: Date) {
  return `${getBasicDateString(date)} 23:59`;
}

export function getBasicDateString(date: Date) {
  // YYYY-MM-DD
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
}

export const daysBefore = (date: Date, days: number) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - days);
};

export const monthsBefore = (date: Date, months: number) => {
  return new Date(date.getFullYear(), date.getMonth() - months, date.getDate());
};

export const yearsBefore = (date: Date, years: number) => {
  return new Date(date.getFullYear() - years, date.getMonth(), date.getDate());
};

export const beginningOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const beginningOfYear = (date: Date) => {
  return new Date(date.getFullYear(), 0, 1);
};

const getWeekday = (date: Date) => date.getDay();

export const previousMonday = (date: Date) => {
  const weekday = getWeekday(date);
  const mondayDateNumber =
    weekday === 0 ? date.getDate() - 6 : date.getDate() - (weekday - 1);
  return new Date(date.getFullYear(), date.getMonth(), mondayDateNumber);
};

export const weekBefore = (date: Date) => daysBefore(date, 7);
