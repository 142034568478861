import * as React from "react";
import { useUserContext } from "../common/UserContext/UserContext";
import { LoginCredentials } from "../common/LoginCredentials/LoginCredentials";
import { StaticRoutePath } from "../Routes";
import { Link, Redirect } from "react-router-dom";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import { GoldenRatioishPage } from "../common/GoldenRatioishPage/GoldenRatioishPage";
import { useState } from "react";
import { useLoginCredentialsClasses } from "../common/LoginCredentials/UseLoginCredentialsClasses";
import { Title } from "../common/Title/Title";

export function Register() {
  const { user, register } = useUserContext();
  const [fullName, setFullName] = useState("");
  const classes = useLoginCredentialsClasses();

  const shouldShowConfirmEmailView = user && !user.verified;

  async function handleSubmit(email: string, password: string) {
    await register(fullName, email, password);
  }

  if (user) {
    return <Redirect to={StaticRoutePath.Root} />;
  }

  return shouldShowConfirmEmailView ? (
    <Redirect to={StaticRoutePath.ConfirmEmail} />
  ) : (
    <GoldenRatioishPage>
      <Title>Register</Title>
      <LoginCredentials
        submitButtonTitle="Register"
        onSubmit={handleSubmit}
        beforeContent={
          <Grid item xs={12}>
            <TextField
              className={classes.input}
              label="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </Grid>
        }
      />
      <Box m={2}>
        <Typography variant="body2">Already registered?</Typography>
        <Link to={StaticRoutePath.Login}>Login</Link>
      </Box>
    </GoldenRatioishPage>
  );
}
