import {
  Theme,
  Tooltip,
  TooltipProps,
  withStyles,
  Zoom,
} from "@material-ui/core";

const HtmlTooltip = withStyles((theme: Theme) => ({
  arrow: {
    "&:before": {
      backgroundColor: "#00008B",
    },
  },
  tooltip: {
    border: "1px solid #00008B",
    background: "#00008B",
    color: "#fff",
    maxWidth: "320px",
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(1.4),
    boxShadow: "3px 4px 15px -3px rgba(11, 11, 11, 0.75)",
  },
  tooltipPlacementTop: {
    top: theme.spacing(1.95),
  },
  tooltipPlacementBottom: {
    bottom: theme.spacing(0.7),
  },
}))(Tooltip);

function CustomHtmlTooltip(props: TooltipProps) {
  return (
    <HtmlTooltip
      arrow
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 320 }}
      placement="top-end"
      {...props}
    />
  );
}
export { HtmlTooltip, CustomHtmlTooltip };
