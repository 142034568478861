import {
  AuthorizationStrategy,
  getWooCommerceVersion,
  IConfigurationWooCommerceSpecific,
  IWooCommerceConfiguration,
  Language,
  Platform,
  WooCommerceApiVersion,
} from "../../common/ConfigurationsContext/Configuration";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  ConfigurationWizardCommonPageType,
  ConfigurationWizardPageProps,
  ConfigurationWizardPageType,
} from "./configurationWizardPage";
import {
  ConfigurationInputContainer,
  ConfigurationInputGroupContainer,
} from "./ConfigurationInputContainer";
import { Title, TitleVariant } from "../../common/Title/Title";
import { CustomHtmlTooltip } from "../../common/Tooltip/Tooltip";

export function WooCommerceSpecificConfiguration({
  id,
  setPageType,
}: ConfigurationWizardPageProps & {
  setPageType: (pageType: ConfigurationWizardPageType) => void;
}) {
  const { configuration, setWooCommerceSpecificConfiguration } =
    useConfiguration(id);
  if (configuration) {
    const { platform } = configuration;
    if (platform !== Platform.WooCommerce) {
      console.log("Not WooCommerce");
      setPageType(ConfigurationWizardCommonPageType.CommonFrontend);
    }
  }
  const { apiVersion, key, secret, woocommerceLanguage } =
    getPartialWooCommerceSpecificConfiguration(
      configuration as IWooCommerceConfiguration
    );
  return (
    <>
      <Title variant={TitleVariant.Secondary}>WooCommerce Information</Title>
      <ConfigurationInputGroupContainer>
        <ConfigurationInputContainer>
          <CustomHtmlTooltip title="Provide the version of your stores WooCommerce installation.">
            <FormControl>
              <InputLabel>WooCommerce version</InputLabel>
              <Select
                value={apiVersion}
                onChange={(event) =>
                  setWooCommerceSpecificConfiguration({
                    apiVersion: event.target.value as WooCommerceApiVersion,
                  })
                }
              >
                <MenuItem value={WooCommerceApiVersion.v3}>
                  {getWooCommerceVersion(WooCommerceApiVersion.v3)}
                </MenuItem>
                <MenuItem value={WooCommerceApiVersion.v2}>
                  {getWooCommerceVersion(WooCommerceApiVersion.v2)}
                </MenuItem>
                <MenuItem value={WooCommerceApiVersion.v1}>
                  {getWooCommerceVersion(WooCommerceApiVersion.v1)}
                </MenuItem>
              </Select>
            </FormControl>
          </CustomHtmlTooltip>
        </ConfigurationInputContainer>
        <ConfigurationInputContainer>
          <CustomHtmlTooltip title="Provide a REST API consumer key for your WooCommerce installation (WooCommerce > Settings > Advanced > REST API).">
            <TextField
              label="Consumer key"
              value={key}
              onChange={(event) =>
                setWooCommerceSpecificConfiguration({ key: event.target.value })
              }
            />
          </CustomHtmlTooltip>
        </ConfigurationInputContainer>
        <ConfigurationInputContainer>
          <CustomHtmlTooltip title="Provide a REST API consumer secret for your WooCommerce installation (WooCommerce > Settings > Advanced > REST API).">
            <TextField
              label="Consumer secret"
              value={secret}
              onChange={(event) =>
                setWooCommerceSpecificConfiguration({
                  secret: event.target.value,
                })
              }
            />
          </CustomHtmlTooltip>
        </ConfigurationInputContainer>
      </ConfigurationInputGroupContainer>
      <ConfigurationInputGroupContainer>
        <ConfigurationInputContainer>
          <InputLabel variant="standard">Product language filter</InputLabel>
          <CustomHtmlTooltip title="Select the language with which to filter products of a single language.">
            <FormControl>
              <Select
                value={woocommerceLanguage || ""}
                onChange={(event) =>
                  setWooCommerceSpecificConfiguration({
                    woocommerceLanguage: event.target.value as Language,
                  })
                }
                defaultValue={""}
                displayEmpty
              >
                <MenuItem value={""}>All products are in one language</MenuItem>
                <MenuItem value={Language.Finnish}>
                  Filter for Finnish products
                </MenuItem>
                <MenuItem value={Language.English}>
                  Filter for English products
                </MenuItem>
                <MenuItem value={Language.Swedish}>
                  Filter for Swedish products
                </MenuItem>
                <MenuItem value={Language.German}>
                  Filter for German products
                </MenuItem>
              </Select>
            </FormControl>
          </CustomHtmlTooltip>
        </ConfigurationInputContainer>
        {/* <ConfigurationInputContainer>
          <Tooltip
            placement="top-end"
            title="Provide the type of authorization used for the query."
          >
            <FormControl>
              <InputLabel>Query authorization type</InputLabel>
              <Select
                value={authStrategy}
                onChange={(event) =>
                  setWooCommerceSpecificConfiguration({
                    authStrategy: event.target.value as AuthorizationStrategy,
                  })
                }
              >
                <MenuItem value={AuthorizationStrategy.basic}>
                  {AuthorizationStrategy.basic}
                </MenuItem>
                <MenuItem value={AuthorizationStrategy.query}>
                  {AuthorizationStrategy.query}
                </MenuItem>
              </Select>
            </FormControl>
          </Tooltip>
        </ConfigurationInputContainer> */}
      </ConfigurationInputGroupContainer>
    </>
  );
}

function getPartialWooCommerceSpecificConfiguration(
  configuration?: IWooCommerceConfiguration
): IConfigurationWooCommerceSpecific {
  return {
    key: configuration?.key ?? "",
    secret: configuration?.secret ?? "",
    apiVersion: configuration?.apiVersion ?? WooCommerceApiVersion.v3,
    authStrategy: configuration?.authStrategy ?? AuthorizationStrategy.basic,
    woocommerceLanguage: configuration?.woocommerceLanguage ?? "",
  };
}
