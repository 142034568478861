export interface PopupOptionsGroup {
  [key: string]: PopupOption;
}

export interface PopupOptionCommon {
  type: PopupOptionType;
  url: string;
}

type PopupOption = PopupOptionCommon | "enabled";

export function createPopupOption(type: PopupOptionType): PopupOption {
  return {
    type,
    url: "",
  };
}

export enum PopupOptionType {
  searchResults = "searchResults",
  close = "close",
  faq = "faq",
  relevantProducts = "relevantProducts",
  productFaq = "productFaq",
  addToCart = "addToCart",
}

export interface IFlowRule {
  trigger: IFlowRuleTrigger;
  buttons: IFlowRuleButtons;
}

interface IFlowRuleTrigger {
  type: FlowRuleType;
  value: string;
  isPurchase?: true;
}

interface IFlowRuleButtons {
  expanded: IFlowRuleButton[];
  initial: IFlowRuleButtonCommon[];
}

export interface IFlowRuleButtonCommon {
  type: UIPopupOptionType;
  text: string;
}

interface IFlowRuleButtonCategories {
  type: CategoriesUIPopupOptionType;
}

export interface IFlowRuleButtonUrl {
  type: UrlUIPopupOptionType;
  text: string;
  url: string;
}

export type IFlowRuleButton =
  | IFlowRuleButtonCommon
  | IFlowRuleButtonCategories
  | IFlowRuleButtonUrl;

export function isCommonButton(
  button: IFlowRuleButton
): button is IFlowRuleButtonCommon {
  return (button as IFlowRuleButtonCommon).text !== undefined;
}

export enum FlowRuleType {
  Url = "Url",
  ClassSelector = "ClassSelector",
  UpsySelector = "UpsySelector",
  HideOnUrl = "HideOnUrl",
}

export function createDefaultFlowRuleUI(): IFlowRule {
  return {
    trigger: {
      type: FlowRuleType.ClassSelector,
      value: "",
    },
    buttons: createDefaultInitialFlowRuleUIButtons(),
  };
}

export function createDefaultInitialFlowRuleUIButtons(): IFlowRuleButtons {
  return {
    initial: [createDefaultFlowRuleUIInitialButton()],
    expanded: [createDefaultFlowRuleUIButton()],
  };
}

export function createDefaultFlowRuleUIButton(): IFlowRuleButton {
  return {
    type: PopupOptionType.addToCart,
    text: "",
  };
}

export function createDefaultFlowRuleUICloseButton(): IFlowRuleButton {
  return {
    type: PopupOptionType.close,
    text: "✕",
  };
}

export function createDefaultFlowRuleUIFaqButton(): IFlowRuleButtonCommon {
  return {
    type: PopupOptionType.faq,
    text: "Kysy myyjältä",
  };
}

export function createDefaultFlowRuleUIInitialButton(
  text: string = ""
): IFlowRuleButtonCommon {
  return {
    type: PopupOptionType.faq,
    text: text || "Kysy myyjältä",
  };
}

export function getRuleTypeName(ruleType: FlowRuleType) {
  if (ruleType === FlowRuleType.Url) {
    return "Url";
  } else if (ruleType === FlowRuleType.ClassSelector) {
    return "Class selector";
  } else if (ruleType === FlowRuleType.HideOnUrl) {
    return "Hide on URL";
  } else {
    return "Upsy selector";
  }
}

export function getRuleTypeTooltip(ruleType: FlowRuleType) {
  if (ruleType === FlowRuleType.Url) {
    return "Specify a (part of) web address, where this rule should be triggered.";
  } else if (ruleType === FlowRuleType.ClassSelector) {
    return "Specify a HTML class, the existence of which on a store page, triggers this rule.";
  } else if (ruleType === FlowRuleType.HideOnUrl) {
    return "Specify a (part of) web address, where Upsy should NOT be visible.";
  } else {
    return "Specify a upsy selector, the existence of which on a store page, triggers this rule.";
  }
}

type CategoriesUIPopupOptionType = "categories";
export const categoriesUIPopupOption: CategoriesUIPopupOptionType =
  "categories";

type UrlUIPopupOptionType = "url";
export const urlUIPopupOption: UrlUIPopupOptionType = "url";

export type UIPopupOptionType =
  | PopupOptionType
  | CategoriesUIPopupOptionType
  | UrlUIPopupOptionType;
