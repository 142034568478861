import * as React from "react";
import { useUserContext } from "../common/UserContext/UserContext";
import { useState } from "react";
import { Link } from "react-router-dom";
import { StaticRoutePath } from "../Routes";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { GoldenRatioishPage } from "../common/GoldenRatioishPage/GoldenRatioishPage";
import { Title } from "../common/Title/Title";

export function ConfirmEmail() {
  const { user, confirmEmail, resendConfirmationCode } = useUserContext();
  const [confirmationCode, setConfirmationCode] = useState("");
  const [codeResent, setCodeResent] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submitError, setSubmitError] = useState<undefined | string>();

  async function confirm() {
    try {
      if (user) {
        await confirmEmail(user.email, confirmationCode);
        setSuccess(true);
      } else {
        throw Error("confirm: Unexpected error");
      }
    } catch (error) {
      setSubmitError(error.message);
    }
  }

  async function resend() {
    try {
      if (user) {
        await resendConfirmationCode(user.email);
        setCodeResent(true);
      } else {
        throw Error("resend: Unexpected error");
      }
    } catch (error) {
      setSubmitError(error.message);
    }
  }

  return (
    <GoldenRatioishPage>
      <Title>Confirm Email</Title>
      <Grid container justify="center">
        <Grid container item spacing={2} xs={12} md={9} lg={6}>
          {success ? (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Email confirmed succesfully!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Link to={StaticRoutePath.Login}>Go to login</Link>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <TextField
                  label="Code"
                  value={confirmationCode}
                  onChange={(e) => setConfirmationCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={confirm}>
                  Confirm
                </Button>
              </Grid>
              {codeResent && (
                <Grid item xs={12}>
                  Code was resent
                </Grid>
              )}
              <Grid item xs={12}>
                <Button variant="outlined" color="primary" onClick={resend}>
                  Resend code{codeResent ? " again" : ""}
                </Button>
              </Grid>
              {submitError && (
                <Grid item xs={12}>
                  {submitError}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </GoldenRatioishPage>
  );
}
