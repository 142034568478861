// Read file as string/text uploaded from html input[type="file"]
export function readFileAsText(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

//Covert csv array to javascript array of objects
export function csvStringToObjectArray(str: string, delimiter = ",") {
  let arr = csvToArray(str);
  arr = arr.filter((item) => item.length && item[0]);
  if (arr.length < 2) return [];
  const headers = arr[0].map((el) => el.trim());
  const rows = arr.slice(1);
  const objArr: { [key: string]: string }[] = [];

  for (let row of rows) {
    if (row && row.length && row[0]) {
      const el = headers.reduce(function (parseObject, header, index) {
        parseObject[header] = row[index] ? row[index].trim() : "";
        return parseObject;
      }, {} as { [key: string]: string });
      objArr.push(el);
    }
  }

  return objArr;
}

//Covert csv string to array
export function csvToArray(text: string) {
  let p = "",
    row = [""],
    ret = [row],
    i = 0,
    r = 0,
    s = !0,
    l;
  for (l of text) {
    if ('"' === l) {
      if (s && l === p) row[i] += l;
      s = !s;
    } else if ("," === l && s) l = row[++i] = "";
    else if ("\n" === l && s) {
      if ("\r" === p) row[i] = row[i].slice(0, -1);
      row = ret[++r] = [(l = "")];
      i = 0;
    } else row[i] += l;
    p = l;
  }
  return ret;
}
