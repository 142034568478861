import * as React from "react";
import { IConfigurationCommonFrontend } from "../../common/ConfigurationsContext/Configuration";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import { ReactNode } from "react";
import { Title, TitleVariant } from "../../common/Title/Title";

export function UpsyPreview({
  avatar,
  buttonColor,
  widgetToLeft,
  buttonTexts,
  style,
}: Pick<IConfigurationCommonFrontend, "avatar" | "buttonColor"> & {
  buttonTexts: string[];
  widgetToLeft?: boolean;
  style?: React.CSSProperties;
}) {
  const classes = useUpsyPreviewClasses();
  return (
    <div className={classes.container} style={{ ...style }}>
      <Title variant={TitleVariant.Secondary}>Upsy Preview</Title>
      <div
        className={`${classes.avatar} ${
          widgetToLeft ? classes.avatarLeft : ""
        }`}
        style={{ backgroundImage: `url(${avatar})` }}
      />
      <div
        className={`${classes.buttonsContainer} ${
          widgetToLeft ? classes.buttonsContainerLeft : ""
        }`}
      >
        {buttonTexts.map((buttonText) => (
          <UpsyButton buttonColor={buttonColor}>{buttonText}</UpsyButton>
        ))}
        <UpsyButton buttonColor={buttonColor}>✕</UpsyButton>
      </div>
    </div>
  );
}

function UpsyButton({
  buttonColor,
  children,
}: Pick<IConfigurationCommonFrontend, "buttonColor"> & {
  children: ReactNode;
}) {
  const classes = useUpsyButtonClasses();
  return (
    <button className={classes.button} style={{ backgroundColor: buttonColor }}>
      {children}
    </button>
  );
}

const useUpsyPreviewClasses = createUseClasses(() => ({
  container: {
    position: "relative",
    width: "100%",
    height: "200px",
    textAlign: "right",
  },
  avatar: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "56px",
    height: "56px",
    boxShadow: "0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%)",
    backgroundColor: "#282828",
    backgroundSize: "cover",
    borderRadius: "50%",
  },
  avatarLeft: {
    right: "auto",
    left: 0,
  },
  buttonsContainer: {
    position: "absolute",
    right: "80px",
    bottom: "20px",
    textAlign: "right",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  buttonsContainerLeft: {
    right: "auto",
    left: "80px",
    alignItems: "flex-start",
  },
}));

const useUpsyButtonClasses = createUseClasses(() => ({
  button: {
    border: "none",
    boxShadow: "0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 9%)",
    fontSize: "13px",
    marginTop: "7px",
    padding: "8px 14px",
    borderRadius: "25px",
    whiteSpace: "nowrap",
    color: "#fff",
    transition: ".3s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#fff !important",
      color: "#282828",
    },
  },
}));
