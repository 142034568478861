import { Box, Button, Grid, Typography } from "@material-ui/core";
import { GoldenRatioishPage } from "../common/GoldenRatioishPage/GoldenRatioishPage";
import { Link } from "react-router-dom";
import { createUseClasses } from "../common/Theme/createUseClasses";

export function NotFound() {
  const classes = useClasses();

  return (
    <GoldenRatioishPage>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box m={2}>
            <Typography className={classes.h1} variant="h1" component="p">
              404
            </Typography>
            <Typography
              variant="h6"
              component="p"
              className={classes.textMuted}
            >
              Sorry!
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.textMuted}
            >
              The page you are looking for does not seem to exist.
            </Typography>
            <Link to="/" className={classes.link}>
              <Button
                className={classes.btn}
                variant="outlined"
                color="default"
              >
                Return home
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </GoldenRatioishPage>
  );
}

const useClasses = createUseClasses((theme) => ({
  h1: {
    lineHeight: 1.3,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "4.5rem",
    },
  },
  btn: {
    textTransform: "initial",
  },
  link: {
    textDecoration: "none",
  },
  textMuted: {
    color: "rgba(128,128,128,0.75)",
  },
}));
