import axios, { AxiosResponse } from "axios";
import { IBackendConfiguration } from "../ConfigurationsContext/Configuration";
import { User } from "../UserContext/User";
import ApiError from "./ApiError";
import {
  CreateUserApiError,
  CreateUserRequest,
  FetchShopConfigurationsResponse,
  GetUserResponse,
  UpsertShopConfigurationOperationType,
  UpsertShopConfigurationRequest,
} from "./BackendResponse";

const backendClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

const addAuthParameters = (user: User) =>
  `user=${encodeURIComponent(
    user?.email || ""
  )}&customerSecretKey=${encodeURIComponent(user?.secretKey || "")}`;

const callBackend = async (
  axiosResponse: Promise<AxiosResponse>,
  errorHandler?: (errData: Object | string) => Error
): Promise<any> => {
  try {
    const response = await axiosResponse;
    return response && response.data;
  } catch (err) {
    if (err.response && err.response.data) {
      if (errorHandler) {
        errorHandler(err.response.data);
      } else {
        throw new Error(err.response.data);
      }
    }
    throw new Error("Unknown error");
  }
};

export const createUser = async (data: CreateUserRequest): Promise<User> =>
  callBackend(
    backendClient.post("user/create", data),
    (errData: CreateUserApiError | string) => {
      throw new ApiError(errData);
    }
  );

export const getUser = async (user: User): Promise<GetUserResponse> =>
  callBackend(backendClient.get(`user${addAuthParameters(user)}`));

export const saveShopConfiguration = async (
  operationType: UpsertShopConfigurationOperationType,
  user: User,
  data: UpsertShopConfigurationRequest
): Promise<void> =>
  callBackend(
    backendClient.post(
      `shop-configuration/upsert?${addAuthParameters(
        user
      )}&type=${operationType}`,
      data
    )
  );

export const fetchShopConfigurations = async (
  user: User
): Promise<Array<FetchShopConfigurationsResponse>> =>
  callBackend(
    backendClient.get(`shop-configuration/list?${addAuthParameters(user)}`)
  );

export const testShopConfiguration = async (
  user: User,
  shopConfiguration: IBackendConfiguration
): Promise<void> =>
  callBackend(
    backendClient.post(
      `shop-configuration/test?${addAuthParameters(user)}`,
      shopConfiguration
    )
  );

export const getQuickSightEmbedUrl = async (user: User): Promise<string> =>
  callBackend(
    backendClient.get(`quicksight-embed-url?${addAuthParameters(user)}`)
  );
