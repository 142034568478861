import { IConfiguration, newConfigurationId } from "./Configuration";
import {
  fetchShopConfigurations,
  saveShopConfiguration,
} from "../Client/backendClient";
import { User } from "../UserContext/User";
import { dashboardTransformationByType } from "./backendToDashboardTransformer";
import { backendTransformationByType } from "./dashboardToBackendTransformer";
import { UpsertShopConfigurationOperationType } from "../Client/BackendResponse";

export async function saveConfiguration(
  configurationToSave: IConfiguration,
  user: User
): Promise<boolean> {
  const transformationSpec =
    backendTransformationByType[
      configurationToSave.platform
        ? configurationToSave.platform.toLowerCase()
        : ""
    ];

  if (transformationSpec) {
    await saveShopConfiguration(
      configurationToSave.id === newConfigurationId
        ? UpsertShopConfigurationOperationType.New
        : UpsertShopConfigurationOperationType.Update,
      user,
      transformationSpec.transform(configurationToSave)
    );
  } else {
    const defaultTransformationSpec = backendTransformationByType["default"];
    await saveShopConfiguration(
      configurationToSave.id === newConfigurationId
        ? UpsertShopConfigurationOperationType.New
        : UpsertShopConfigurationOperationType.Update,
      user,
      defaultTransformationSpec.transform(configurationToSave)
    );
  }

  return new Promise((resolve) => {
    setTimeout(() => resolve(true), 50);
  });
}

export async function loadConfigurations(
  user: User
): Promise<IConfiguration[]> {
  if (user) {
    const shopConfigurations = await fetchShopConfigurations(user);
    return shopConfigurations.map((sc) => {
      const { type } = sc.backendConfiguration;
      if (type) {
        const spec = dashboardTransformationByType[type.toUpperCase()];
        if (spec) {
          return spec.transform(sc);
        }
      }
      const defaultSpec = dashboardTransformationByType["default"];
      return defaultSpec.transform(sc);
    });
  }

  return Promise.resolve([]);
}
