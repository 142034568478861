import * as React from "react";
import { ReactNode } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  ThemeProvider,
  createMuiTheme,
  unstable_createMuiStrictModeTheme,
  colors,
} from "@material-ui/core";
import { Overrides } from "@material-ui/core/styles/overrides";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";

export function Theme({ children }: { children: ReactNode }) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </>
  );
}

const overrides: Overrides = {
  MuiFormControl: {
    root: {
      width: "100%",
    },
  },

  MuiInputBase: {
    root: {
      "&.MuiInput-underline:before": {
        borderBottomColor: colors.grey[300],
      },
      "&.MuiInput-underline:after, &.MuiInput-underline:hover:not(.Mui-disabled):before":
        {
          borderBottomColor: "#9077FD",
        },
    },
  },

  MuiPaper: {
    elevation1: {
      boxShadow: "0px 4px 12px 0px rgba(149, 157, 165, 0.18)",
    },
  },
};

const palette: PaletteOptions = {
  primary: {
    main: "#1A0E52",
  },
  secondary: {
    main: "#E26179",
  },
  text: {
    primary: "#1A0E52",
  },
  background: {
    default: "#FFF",
    paper: "#F7F9FF",
  },
};

const typography: TypographyOptions = {
  fontFamily: '"Montserrat", sans-serif',
  h4: {
    // Main heading
    fontFamily: '"Days One", sans-serif',
  },
};

const theme = createMuiTheme({
  overrides,
  palette,
  typography,
});
