import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MobileStepper,
  Step,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { IConfiguration } from "../../common/ConfigurationsContext/Configuration";
import {
  useCommonBackendConfigurationValidator,
  useConfiguration,
  useConfigurations,
  useConfigurationTester,
} from "../../common/ConfigurationsContext/ConfigurationsContext";
import { CommonBackendConfiguration } from "./CommonBackendConfiguration";
import { WooCommerceSpecificConfiguration } from "./WooCommerceSpecificConfiguration";
import { CommonFrontendConfiguration } from "./CommonFrontendConfiguration";
import { ConfigurationSummary } from "./ConfigurationSummary";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import { CommonFrontendFlowRules } from "./CommonFrontendFlowRules";
import {
  ConfigurationWizardCommonPageType,
  ConfigurationWizardPageProps,
  configurationWizardPagesByType,
  FEConfigurationWizardPages,
  ConfigurationWizardPageType,
} from "./configurationWizardPage";
import { ConfigurationInputGroupContainer } from "./ConfigurationInputContainer";
import { LoadingButton } from "../../common/LoadingButton/LoadingButton";
import { ConfigurationSuccess } from "./ConfigurationSuccess";
import { CommonFrontendFAQs } from "./CommonFrontendFAQs";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export type ConfigurationStepperProps = {
  steps: string[];
  activeStep: number;
  breakpointDirection?: "up" | "down";
  breakpoint?: "xs" | "sm" | "md" | "lg" | "xl";
};

const colorCompleted = "rgb(136,106,253)";
const colorActive = "rgb(37,155,35)";

const useConfigurationStepperClasses = createUseClasses((theme) => ({
  root: {
    padding: 6,
    "&.MuiStepper-root .MuiStepConnector-root.MuiStepConnector-completed .MuiStepConnector-line":
      {
        borderColor: colorCompleted,
        borderWidth: 1.6,
      },
    "&.MuiStepper-root .MuiStepConnector-root.MuiStepConnector-active .MuiStepConnector-line":
      {
        borderColor: colorActive,
        borderWidth: 1.6,
      },
    "&.MuiStepper-root .MuiStepLabel-iconContainer .MuiStepIcon-root.MuiStepIcon-active":
      {
        color: colorActive,
      },
    "&.MuiStepper-root .MuiStepLabel-iconContainer .MuiStepIcon-root.MuiStepIcon-completed":
      {
        color: colorCompleted,
      },
    "&.MuiStepper-root .MuiStepLabel-root.MuiStepLabel-horizontal.MuiStepLabel-alternativeLabel .MuiStepLabel-label":
      {
        margin: 1,
      },
    "&.MuiStepper-root .MuiStepLabel-root .MuiStepLabel-label.MuiStepLabel-active":
      {
        color: colorActive,
      },
    "&.MuiStepper-root .MuiStepLabel-root .MuiStepLabel-label.MuiStepLabel-completed":
      {
        color: colorCompleted,
      },
  },
}));

export function ConfigurationStepper({
  steps,
  activeStep,
  breakpointDirection = "down",
  breakpoint = "sm",
}: ConfigurationStepperProps) {
  const theme = useTheme();
  const matches = useMediaQuery(
    theme.breakpoints[breakpointDirection](breakpoint)
  );
  const classes = useConfigurationStepperClasses();

  return (
    <>
      {matches ? (
        <Stepper
          classes={{ root: classes.root }}
          activeStep={activeStep}
          orientation="vertical"
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Stepper
          classes={{ root: classes.root }}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: React.ReactNode } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
    </>
  );
}

const useConfigurationWizardClasses = createUseClasses((theme) => ({
  stepperWrapper: {
    marginTop: 16,
    marginBottom: 16,
  },
}));

function getStepperLabelsFromPageTypes(
  configurationsPages: ConfigurationWizardPageType[]
) {
  return configurationsPages
    .map((pageType) => {
      switch (pageType) {
        case ConfigurationWizardCommonPageType.CommonBackend:
          return "General configurations";
        case ConfigurationWizardCommonPageType.StoreSpecificBackend:
          return "Store platform specific configurations";
        case ConfigurationWizardCommonPageType.CommonFrontend:
          return "Upsy widget personalization";
        case ConfigurationWizardCommonPageType.CommonFrontendFlowRules:
          return "Flow rules configurations";
        case ConfigurationWizardCommonPageType.CommonFrontendFAQs:
          return "Add/update FAQs";
        case ConfigurationWizardCommonPageType.Summary:
          return "Configurations summary";
        case ConfigurationWizardCommonPageType.Success:
          return "";
        default:
          return "";
      }
    })
    .filter((item) => item);
}

export function ConfigurationWizard({ id }: { id: string }) {
  const { configuration } = useConfiguration(id);
  const platform = configuration?.platform;
  const configurationWizardPages = platform
    ? configurationWizardPagesByType[platform]
    : FEConfigurationWizardPages;
  const [pageType, setPageType] = useState<ConfigurationWizardPageType>(
    configurationWizardPages[0]
  );
  const [steps, setSteps] = useState<string[]>([]);

  useEffect(() => {
    setSteps((prev) => [
      ...getStepperLabelsFromPageTypes(configurationWizardPages),
    ]);
  }, []);

  const classes = useConfigurationWizardClasses();
  const [activeStep, setActiveStep] = useState<number>(0);

  return (
    <>
      <div className={classes.stepperWrapper}>
        <ConfigurationStepper steps={steps} activeStep={activeStep} />
      </div>
      <ConfigurationWizardPage
        id={id}
        pageType={pageType}
        setPageType={setPageType}
      />
      <ConfigurationWizardNavigation
        id={id}
        pageType={pageType}
        setPageType={setPageType}
        configurationWizardPages={configurationWizardPages}
        setActiveStep={setActiveStep}
      />
    </>
  );
}

function ConfigurationWizardPage({
  id,
  pageType,
  setPageType,
}: ConfigurationWizardPageProps & {
  pageType: ConfigurationWizardPageType;
  setPageType: (pageType: ConfigurationWizardPageType) => void;
}) {
  switch (pageType) {
    case ConfigurationWizardCommonPageType.CommonBackend:
      return <CommonBackendConfiguration id={id} />;
    case ConfigurationWizardCommonPageType.StoreSpecificBackend:
      return (
        <WooCommerceSpecificConfiguration id={id} setPageType={setPageType} />
      );
    case ConfigurationWizardCommonPageType.CommonFrontend:
      return <CommonFrontendConfiguration id={id} />;
    case ConfigurationWizardCommonPageType.CommonFrontendFlowRules:
      return <CommonFrontendFlowRules id={id} />;
    case ConfigurationWizardCommonPageType.CommonFrontendFAQs:
      return <CommonFrontendFAQs id={id} />;
    case ConfigurationWizardCommonPageType.Summary:
      return <ConfigurationSummary id={id} />;
    case ConfigurationWizardCommonPageType.Success:
      return <ConfigurationSuccess id={id} />;
    default:
      return null;
  }
}

const isIgnoreErrorBoxEnabled: boolean =
  process.env.REACT_APP_ENABLE_IGNORE_TEST_ERROR_BOX === "true";

function ConfigurationWizardNavigation({
  id,
  pageType,
  setPageType,
  setActiveStep,
  configurationWizardPages,
}: ConfigurationWizardPageProps & {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  pageType: ConfigurationWizardPageType;
  setPageType: (pageType: ConfigurationWizardPageType) => void;
  configurationWizardPages: ConfigurationWizardPageType[];
}) {
  const { configuration, configurationError } = useConfiguration(id);
  const { testingConfiguration, testingError, testBackendShopConfiguration } =
    useConfigurationTester(configuration);

  const {
    commonBackendConfigurationValidationError,
    validateCommonBackendConfiguration,
  } = useCommonBackendConfigurationValidator(configuration);

  const classes = useNavigationButtonsClasses();
  const [ignoreError, setIgnoreError] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const minIndex = 0;
  const isCommonBackendConfigPage =
    pageType === ConfigurationWizardCommonPageType.CommonBackend;
  const isShopConfigTestPage =
    pageType === ConfigurationWizardCommonPageType.StoreSpecificBackend;
  const isSummaryPage = pageType === ConfigurationWizardCommonPageType.Summary;
  const isSuccessPage = pageType === ConfigurationWizardCommonPageType.Success;

  useEffect(() => {
    setActiveStep(pageIndex);
  }, [pageIndex, setActiveStep]);

  const moveToPage = (pageIndex: number) => {
    setPageIndex(pageIndex);
    const pageType = configurationWizardPages[pageIndex];
    if (pageType === ConfigurationWizardCommonPageType.StoreSpecificBackend) {
      console.log("Landed on store specific config page");
      setPageType(pageType);
    } else {
      setPageType(pageType);
    }
  };

  function previous() {
    if (minIndex < pageIndex) {
      moveToPage(pageIndex - 1);
    }
  }

  return (
    <ConfigurationInputGroupContainer noSpacing>
      {!isSuccessPage && (
        <Grid item xs={12}>
          <MobileStepper
            className={classes.buttonsContainer}
            backButton={
              <Button
                variant="outlined"
                color="primary"
                disabled={
                  minIndex === pageIndex ||
                  isSuccessPage ||
                  testingConfiguration
                }
                onClick={previous}
              >
                Previous
              </Button>
            }
            nextButton={
              <ProceedButton
                id={id}
                pageType={pageType}
                pageIndex={pageIndex}
                moveToPage={moveToPage}
                testingConfiguration={testingConfiguration}
                testBackendShopConfiguration={testBackendShopConfiguration}
                validateCommonBackendConfiguration={
                  validateCommonBackendConfiguration
                }
                ignoreError={ignoreError}
                configurationWizardPages={configurationWizardPages}
              />
            }
            steps={configurationWizardPages.length - 1}
            activeStep={pageIndex}
            position="static"
          />
        </Grid>
      )}
      {isShopConfigTestPage && testingError && (
        <Grid item xs={12}>
          <Alert className={classes.alert} severity="error">
            {testingError}
          </Alert>
          {isIgnoreErrorBoxEnabled && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={ignoreError}
                  onChange={(event) => setIgnoreError(event.target.checked)}
                />
              }
              label="Ignore Error"
            />
          )}
        </Grid>
      )}
      {isCommonBackendConfigPage && commonBackendConfigurationValidationError && (
        <Grid item xs={12}>
          <Alert className={classes.alert} severity="error">
            {commonBackendConfigurationValidationError}
          </Alert>
        </Grid>
      )}
      {isSummaryPage && configurationError && (
        <Grid item xs={12}>
          <Alert className={classes.alert} severity="error">
            {configurationError}
          </Alert>
        </Grid>
      )}
    </ConfigurationInputGroupContainer>
  );
}

function ProceedButton({
  id,
  pageType,
  pageIndex,
  moveToPage,
  testingConfiguration,
  ignoreError,
  testBackendShopConfiguration,
  validateCommonBackendConfiguration,
  configurationWizardPages,
}: ConfigurationWizardPageProps & {
  pageType: ConfigurationWizardPageType;
  pageIndex: number;
  moveToPage: (index: number) => void;
  testingConfiguration: boolean;
  ignoreError: boolean;
  testBackendShopConfiguration: (successCallback: () => void) => void;
  validateCommonBackendConfiguration: (successCallback: () => void) => void;
  configurationWizardPages: ConfigurationWizardPageType[];
}) {
  const maxIndex = configurationWizardPages.length - 1;
  const isCommonBackendPage =
    pageType === ConfigurationWizardCommonPageType.CommonBackend;
  const isShopConfigTestPage =
    pageType === ConfigurationWizardCommonPageType.StoreSpecificBackend;
  const isSummaryPage = pageType === ConfigurationWizardCommonPageType.Summary;
  const isSuccessPage = pageType === ConfigurationWizardCommonPageType.Success;
  const { configuration, saveConfiguration, clearTempConfiguration } =
    useConfiguration(id);
  const { loading } = useConfigurations();

  function next() {
    if (pageIndex < maxIndex) {
      moveToPage(pageIndex + 1);
    }
  }

  return isCommonBackendPage ? (
    <Button
      variant="contained"
      color="primary"
      onClick={() => validateCommonBackendConfiguration(next)}
    >
      Next
    </Button>
  ) : isShopConfigTestPage ? (
    <LoadingButton
      loading={testingConfiguration}
      loadingText="Verifying..."
      buttonText="Next"
      onClick={ignoreError ? next : () => testBackendShopConfiguration(next)}
    />
  ) : isSummaryPage ? (
    <LoadingButton
      loading={loading}
      loadingText="Saving..."
      buttonText="Save and Publish"
      onClick={async () => {
        try {
          await saveConfiguration(configuration as IConfiguration);
          clearTempConfiguration();
          next();
        } catch (err) {}
      }}
    />
  ) : isSuccessPage ? null : (
    <Button variant="contained" color="primary" onClick={next}>
      Next
    </Button>
  );
}

const useNavigationButtonsClasses = createUseClasses((theme) => ({
  alert: {
    whiteSpace: "pre-wrap",
  },
  buttonsContainer: {
    padding: `${theme.spacing(2)}px 0`,
  },
}));
