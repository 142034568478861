import * as React from "react";
import { useUserContext } from "../common/UserContext/UserContext";
import { LoginCredentials } from "../common/LoginCredentials/LoginCredentials";
import { Link, Redirect } from "react-router-dom";
import { StaticRoutePath } from "../Routes";
import { Box } from "@material-ui/core";
import { GoldenRatioishPage } from "../common/GoldenRatioishPage/GoldenRatioishPage";
import { Title } from "../common/Title/Title";

export function Login() {
  const { user, logIn } = useUserContext();

  if (user) {
    return <Redirect to={StaticRoutePath.Root} />;
  }

  return (
    <GoldenRatioishPage>
      <Title>Login</Title>
      <LoginCredentials submitButtonTitle="Login" onSubmit={logIn} />
      <Box m={1}>
        <Box m={1}>
          <Link to={StaticRoutePath.Register}>Register</Link>
        </Box>
        <Box m={1}>
          <Link to={StaticRoutePath.ForgotPassword}>Forgot password</Link>
        </Box>
      </Box>
    </GoldenRatioishPage>
  );
}
