import * as React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  embedQuicksight,
  QuicksightEmbedDateParameters,
  QuicksightEmbedParameters,
  setQuicksightParameters,
} from "./quicksight";
import { useUserContext } from "../../common/UserContext/UserContext";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import { Store } from "../Store";
import { getQuickSightEmbedUrl } from "../../common/Client/backendClient";
import { getBasicDateString } from "./dateUtils";
import { useParams } from "react-router-dom";
import { IStoreRouteParams } from "../../Routes";
import { dateFilterShortcuts } from "./dateFilterShortcuts";

export function Metrics() {
  const classes = useMetricsClasses();
  const dateFilters = useDateFilters();
  const { dateRange, comparisonDateRange } = dateFilters;
  const { iframeRef, loaded } = useEmbed({
    dateRange,
    comparisonDateRange,
  });

  return (
    <Store>
      <Filters {...dateFilters} />
      <div className={classes.metricsContainer} ref={iframeRef}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={`${classes.loaderContainer} ${
            loaded ? classes.loaded : ""
          }`}
        >
          <CircularProgress />
        </Grid>
      </div>
    </Store>
  );
}

function Filters({
  dateRange,
  comparisonDateRange,
  setDateRanges,
}: DateFilters) {
  const [start, setStart] = useState(getBasicDateString(dateRange.start));
  const [end, setEnd] = useState(getBasicDateString(dateRange.end));
  const [comparisonStart, setComparisonStart] = useState(
    getBasicDateString(comparisonDateRange.start)
  );
  const [comparisonEnd, setComparisonEnd] = useState(
    getBasicDateString(comparisonDateRange.end)
  );
  const classes = useFilterClasses();

  const setDateFilterDates = (dates: Date[]) => {
    const [startDate, endDate, comparisonStartDate, comparisonEndDate] = dates;
    setStart(getBasicDateString(startDate));
    setEnd(getBasicDateString(endDate));
    setComparisonStart(getBasicDateString(comparisonStartDate));
    setComparisonEnd(getBasicDateString(comparisonEndDate));
  };

  const [dateFilterShortcutIndex, setDateFilterShortcutIndex] = useState(0);
  const [updateDateRangesFlag, setupdateDateRangesFlag] = useState(false);
  //Default date filter label to "Last 30 Days"
  const defaultDateFilterShortcutIndex = 7;

  useEffect(() => {
    setDateFilterShortcutIndex(defaultDateFilterShortcutIndex);
    setDateFilterDates(
      dateFilterShortcuts[defaultDateFilterShortcutIndex].setFilter()
    );
    setupdateDateRangesFlag(true);
  }, []);

  useEffect(() => {
    if (updateDateRangesFlag) {
      setDateRanges({
        dateRange: {
          start: new Date(start),
          end: new Date(end),
        },
        comparisonDateRange: {
          start: new Date(comparisonStart),
          end: new Date(comparisonEnd),
        },
      });
      setupdateDateRangesFlag(false);
    }
  }, [
    updateDateRangesFlag,
    start,
    end,
    comparisonStart,
    comparisonEnd,
    setDateRanges,
  ]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        setDateRanges({
          dateRange: {
            start: new Date(start),
            end: new Date(end),
          },
          comparisonDateRange: {
            start: new Date(comparisonStart),
            end: new Date(comparisonEnd),
          },
        });
      }}
    >
      <Box mt={1}>
        <Grid container justify="flex-end" alignItems="center" spacing={1}>
          <Grid item xs={6} md={3}>
            <TextField
              label="Start"
              type="date"
              size="small"
              value={start}
              onChange={(event) => setStart(event.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              label="End"
              type="date"
              size="small"
              value={end}
              onChange={(event) => setEnd(event.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              label="Comparison start"
              type="date"
              size="small"
              value={comparisonStart}
              onChange={(event) => setComparisonStart(event.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              label="Comparison end"
              type="date"
              size="small"
              value={comparisonEnd}
              onChange={(event) => setComparisonEnd(event.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={1} mb={2.5}>
        <Grid container justify="flex-start" alignItems="flex-end">
          <Grid item xs={6} md={3}>
            <InputLabel shrink={true}>Date Filter Shortcut</InputLabel>
            <Select
              fullWidth
              label="Date Filter Shortcut"
              value={dateFilterShortcutIndex}
              onChange={(event) => {
                setDateFilterShortcutIndex(event.target.value as number);
                setDateFilterDates(
                  dateFilterShortcuts[event.target.value as number].setFilter()
                );
              }}
            >
              {dateFilterShortcuts.map((shortcut, i) => {
                return <MenuItem value={i}>{shortcut.label}</MenuItem>;
              })}
            </Select>
          </Grid>
          <Grid
            item
            xs={6}
            md={9}
            container
            alignItems="flex-end"
            alignContent="flex-end"
            justify="flex-start"
          >
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.submitButton}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

type DateFilters = QuicksightEmbedDateParameters &
  SetQuicksightEmbedDateParameters;

interface SetQuicksightEmbedDateParameters {
  setDateRanges: (dateRanges: QuicksightEmbedDateParameters) => void;
}

function useDateFilters(): DateFilters {
  const dateRangeBaseDate = new Date();
  dateRangeBaseDate.setDate(new Date().getDate() - 1);

  const comparisonRangeBaseDate = new Date();
  comparisonRangeBaseDate.setDate(new Date().getDate() - 7);

  const [dateRanges, setDateRanges] = useState<QuicksightEmbedDateParameters>({
    dateRange: {
      start: dateRangeBaseDate,
      end: dateRangeBaseDate,
    },
    comparisonDateRange: {
      start: comparisonRangeBaseDate,
      end: comparisonRangeBaseDate,
    },
  });

  return {
    ...dateRanges,
    setDateRanges,
  };
}

function useEmbed(parameters: QuicksightEmbedDateParameters) {
  const { id } = useParams<IStoreRouteParams>();
  const quicksightParameters: Partial<QuicksightEmbedParameters> = useMemo(
    () => ({
      ...parameters,
      tenantId: id,
    }),
    [parameters, id]
  );
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [loaded, setLoaded] = useState(false);

  const { user } = useUserContext();
  const [url, setUrl] = useState<undefined | string>();
  const [dashboard, setDashboard] = useState<any>();

  useEffect(() => {
    getQuickSightEmbedUrl(user).then(setUrl);
  }, [user]);

  useEffect(() => {
    if (iframeRef.current && url) {
      setDashboard(
        embedQuicksight(
          iframeRef.current,
          url,
          quicksightParameters as QuicksightEmbedParameters,
          setLoaded
        )
      );
    }
    // eslint-disable-next-line
  }, [url]);

  const dependencyParameterString = JSON.stringify(quicksightParameters);
  useEffect(() => {
    if (dashboard && loaded) {
      setQuicksightParameters(
        dashboard as any,
        quicksightParameters as QuicksightEmbedParameters
      );
    }
  }, [dashboard, quicksightParameters, loaded, dependencyParameterString]);

  return {
    iframeRef,
    loaded,
  };
}

const useMetricsClasses = createUseClasses(() => ({
  metricsContainer: {
    height: "3000px",
    position: "relative",
  },
  loaderContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 1,
    transition: "opacity 0.5s",
  },
  loaded: {
    opacity: 0,
    pointerEvents: "none",
  },
}));

const useFilterClasses = createUseClasses((theme) => ({
  submitButton: {
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(2),
  },
}));
