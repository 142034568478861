import {
  defaultPlatform,
  IConfiguration,
  IConfigurationCommonBackend,
  newConfigurationId,
  Platform,
} from "../../common/ConfigurationsContext/Configuration";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { ConfigurationWizardPageProps } from "./configurationWizardPage";
import {
  ConfigurationInputContainer,
  ConfigurationInputGroupContainer,
} from "./ConfigurationInputContainer";
import { Title, TitleVariant } from "../../common/Title/Title";
import { CustomHtmlTooltip } from "./../../common/Tooltip/Tooltip";

export function CommonBackendConfiguration({
  id,
}: ConfigurationWizardPageProps) {
  const { configuration, setCommonBackendConfiguration } = useConfiguration(id);
  const { platform, storeName, storeNameFormatted, storeUrl } =
    getPartialCommonBackendConfiguration(configuration);
  return (
    <>
      <Title variant={TitleVariant.Secondary}>Store Platform Information</Title>
      <ConfigurationInputGroupContainer>
        <ConfigurationInputContainer>
          <CustomHtmlTooltip title="Select the ecommerce platform your store is based on">
            <FormControl>
              <InputLabel>Platform</InputLabel>
              <Select
                value={platform}
                onChange={(event) =>
                  setCommonBackendConfiguration({
                    platform: event.target.value as Platform,
                  })
                }
              >
                {[Platform.WooCommerce].map((platform) => {
                  return <MenuItem value={platform}>{platform}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </CustomHtmlTooltip>
        </ConfigurationInputContainer>
        <ConfigurationInputContainer>
          <CustomHtmlTooltip title="Give a name to your store. The name must be one worded (no spaces).">
            <TextField
              required
              label="Store Name"
              InputProps={{
                readOnly: id !== newConfigurationId,
              }}
              value={storeNameFormatted || storeName}
              inputProps={{ maxLength: 25 }}
              onChange={(event) =>
                setCommonBackendConfiguration({
                  storeName: event.target.value.replace(/ /g, "_"),
                })
              }
            />
          </CustomHtmlTooltip>
        </ConfigurationInputContainer>
        <ConfigurationInputContainer>
          <CustomHtmlTooltip title="Provide the web address of your store.">
            <TextField
              required
              label="URL"
              value={storeUrl}
              onChange={(event) =>
                setCommonBackendConfiguration({ storeUrl: event.target.value })
              }
            />
          </CustomHtmlTooltip>
        </ConfigurationInputContainer>
      </ConfigurationInputGroupContainer>
    </>
  );
}

function getPartialCommonBackendConfiguration(
  configuration?: IConfiguration
): IConfigurationCommonBackend {
  console.log(configuration);
  return {
    id: configuration?.id ?? newConfigurationId,
    platform: configuration?.platform ?? defaultPlatform,
    storeName: configuration?.storeName ?? "",
    storeNameFormatted: configuration?.storeNameFormatted ?? "",
    storeUrl: configuration?.storeUrl ?? "",
  };
}
