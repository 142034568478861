import { useParams } from "react-router-dom";
import { IStoreRouteParams } from "../../Routes";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import { ConfigurationWizard } from "./ConfigurationWizard";
import { Store } from "../Store";
import { newConfigurationId } from "../../common/ConfigurationsContext/Configuration";

export function StoreConfiguration() {
  const { id } = useParams<IStoreRouteParams>();
  const { configuration } = useConfiguration(id);
  return (
    <Store>
      {(id === newConfigurationId || configuration) && (
        <ConfigurationWizard id={id} />
      )}
    </Store>
  );
}
