import { ConfigurationWizardPageProps } from "./configurationWizardPage";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add, Delete, ExpandLess, ExpandMore } from "@material-ui/icons";
import React, {
  Dispatch,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  addFAQ,
  removeFAQ,
  FAQsAction,
  init,
  setQuestionText,
  setAnswerText,
  setFAQType,
  setFAQRank,
  addBulkFAQ,
} from "./FAQsReducer";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import { ConfigurationInputGroupContainer } from "./ConfigurationInputContainer";
import { Title, TitleVariant } from "../../common/Title/Title";
import { FAQType, getFAQTypeName, IFAQ } from "./FAQs";
import FileUploadWidget, {
  OnFileChangeEventType,
} from "../../common/FileUploadWidget/FileUploadWidget";
import {
  csvStringToObjectArray,
  readFileAsText,
} from "../../common/utils/file";
import { createDefaultFAQ } from "./FAQs";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import { Alert, Pagination } from "@material-ui/lab";
import SettingsIcon from "@material-ui/icons/Settings";
import usePagination from "../../common/Hooks/usePagination";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export interface IFAQUploadMsgType {
  success: string | React.ReactElement;
  fail: string | React.ReactElement;
}

//Only csv file type are allowed
const allowedUploadFileTypes = ["text/csv", "application/vnd.ms-excel"];

//Convert any csv parse object array to faq object array
function csvToFaqs(csvArr: { [key: string]: string }[]): IFAQ[] {
  if (!csvArr.length) {
    return [];
  }

  const faqs: IFAQ[] = [];
  const defaultFAQ = createDefaultFAQ();
  const faqKeys = Object.keys(defaultFAQ);

  for (const csvObject of csvArr) {
    const hasAllFaqProps = faqKeys.every((key) =>
      Object.prototype.hasOwnProperty.call(csvObject, key)
    );
    if (hasAllFaqProps) {
      const faq = faqKeys.reduce(function (parseObject, key) {
        key = key.trim();
        if (key === "type") {
          if (
            csvObject[key].toLowerCase() === FAQType.Text.toLowerCase() ||
            csvObject[key].toLowerCase() === FAQType.Url.toLowerCase()
          ) {
            parseObject[key] = csvObject[key]
              .trim()
              .replace(/\b\w/g, function (faqType) {
                return faqType.toUpperCase();
              });
          } else {
            parseObject[key] = FAQType.Text;
          }
        } else {
          parseObject[key] = csvObject[key] ? csvObject[key].trim() : "";
        }
        return parseObject;
      }, {} as { [key: string]: string });

      faqs.push(faq as unknown as IFAQ);
    }
  }

  return faqs;
}

export function CommonFrontendFAQs({ id }: ConfigurationWizardPageProps) {
  const { dispatchFAQsAction, customQnA } = useFAQs(id);
  const itemsPerPage = 10;
  const {
    jumpToPage,
    prevPage,
    getPaginatedData,
    maxPage,
    startIndex,
    currentPage,
  } = usePagination(customQnA, itemsPerPage);

  const currentFaqs = getPaginatedData();
  const onPaginationChange = (event: object, page: number) => {
    jumpToPage(page);
  };

  return (
    <>
      <Title variant={TitleVariant.Secondary}>FAQs</Title>
      <Typography variant="body1" gutterBottom>
        The first five (5) FAQs will be shown by default in the FAQ search view.
        The rest will still be searchable by the user.
      </Typography>
      <CsvFAQUpload id={id} />
      <ConfigurationInputGroupContainer>
        {currentFaqs.length > 0 ? (
          currentFaqs.map((FAQ, index) => (
            <FAQItem
              {...FAQ}
              key={"FAQLISTITEM" + (FAQ.originalIndex || index)}
              index={index + startIndex}
              dispatch={dispatchFAQsAction}
              faqs={customQnA}
            />
          ))
        ) : (
          <EmptyFAQPage currentPage={currentPage} prevPageFunc={prevPage} />
        )}

        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              disabled={maxPage < 1}
              count={maxPage}
              page={currentPage}
              onChange={onPaginationChange}
              showFirstButton
              showLastButton
            />
          </Grid>
        </Grid>
      </ConfigurationInputGroupContainer>
      <div>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => dispatchFAQsAction(addFAQ())}
        >
          Add FAQ
        </Button>
      </div>
    </>
  );
}

type FAQProps = {
  index: number;
  dispatch: Dispatch<FAQsAction>;
  faqs?: IFAQ[];
} & IFAQ;

function EmptyFAQPage({
  currentPage,
  prevPageFunc,
}: {
  currentPage: number;
  prevPageFunc: Function;
}) {
  return (
    <Grid container alignItems="center" justify="center">
      <Grid item>
        <Box p={6}>
          <Typography variant="h6" align="center">
            This page is empty
          </Typography>
          {currentPage > 1 && (
            <Box mt={3}>
              <Button
                variant="outlined"
                onClick={(e) => {
                  prevPageFunc();
                }}
              >
                <ArrowBackIcon />
                Go to previous page
              </Button>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
function CsvFAQUpload({ id }: ConfigurationWizardPageProps) {
  const { dispatchFAQsAction } = useFAQs(id);
  const [file, setFile] = useState<File | null>(null);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [uploadMsg, setUploadMsg] = useState<IFAQUploadMsgType>({
    success: "",
    fail: "",
  });
  const maxFaqUploadLimit = 50;
  const classes = useCsvFaqUploadClasses();

  const handleCollapsable = () => {
    setCollapseOpen(!collapseOpen);
  };

  const loadCsvData = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsCsvLoading(true);
    readFileAsText(file as File)
      .then((readAsText) => {
        const csvObjArr = csvStringToObjectArray(readAsText);
        let faqs = csvToFaqs(csvObjArr);
        if (faqs.length) {
          if (faqs.length > maxFaqUploadLimit) {
            faqs = faqs.slice(0, maxFaqUploadLimit);
          }
          dispatchFAQsAction(addBulkFAQ(faqs));
          setFile(null);
          setUploadMsg({
            success:
              "The CSV file was processed successfully and you should find the given FAQ items at the bottom of the list.",
            fail: "",
          });
        } else {
          setFile(null);
          setUploadMsg({
            success: "",
            fail: (
              <span>
                Sorry! you haven't provided a valid FAQ data in the CSV file.
                Please look into the sample FAQ CSV file to know about the
                format. <a href="/csv-faq-example.csv">CSV TEMPLATE LINK</a>
              </span>
            ),
          });
        }

        setIsCsvLoading(false);
      })
      .catch((e) => {
        setFile(null);
        setUploadMsg({
          success: "",
          fail: (
            <span>
              Sorry! Something went wrong. The processing of the CSV file was
              failed. Please try again or look into the sample CSV file to know
              about the valid format.{" "}
              <a href="/csv-faq-example.csv">CSV TEMPLATE LINK</a>
            </span>
          ),
        });
        setIsCsvLoading(false);
      });
  };

  const handleFileChange: OnFileChangeEventType = (e) => {
    if (!file) {
      const target = e.target;
      const uploadFile = target && target.files && target.files[0];
      const type = uploadFile && uploadFile.type;

      //Only csv file type are acceptable
      if (type && allowedUploadFileTypes.includes(type)) {
        setFile(uploadFile);
        setUploadMsg({
          success: "The CSV file was uploaded successfully.",
          fail: "",
        });
      } else {
        setUploadMsg({
          success: "",
          fail: "Sorry! Please upload a CSV file with right format.",
        });
        setFile(null);
      }
    } else {
      setUploadMsg({
        success: "",
        fail: "Please remove the uploaded CSV file first or load the FAQ from uploaded CSV file.",
      });
    }

    e.target.value = "";
  };

  const handleFileClose = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setFile(null);
  };

  const handleAlertClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setUploadMsg({
      success: "",
      fail: "",
    });
  };

  return (
    <>
      <List
        component="nav"
        aria-labelledby="collapseable-faq-upload"
        subheader={
          <ListSubheader
            disableSticky
            component="div"
            id="collapseable-faq-upload"
          >
            Advanced settings
          </ListSubheader>
        }
      >
        <Grid container>
          <Grid item xs={12} lg={6}>
            <ListItem
              className={classes.listItem}
              alignItems="center"
              button
              onClick={handleCollapsable}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography align="left" variant="body1">
                    Upload FAQ from csv file
                  </Typography>
                }
              />
              {collapseOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </Grid>
        </Grid>
        <Collapse
          in={collapseOpen}
          timeout={{ enter: 380, exit: 360 }}
          unmountOnExit
        >
          <Box mt={4}>
            <Grid item xs={12} lg={6}>
              <Box mt={-3} mb={2}>
                <Typography variant="body1">
                  Please download the csv template below and fill in the
                  following information in lower case letters:
                </Typography>
                <ul>
                  <li>
                    FAQ type (accepted values are <b>text</b> or <b>URL</b> if
                    using hyperlinks)
                  </li>
                  <li>Question</li>
                  <li>Answer</li>
                </ul>
                <Typography variant="body2">
                  Maximum FAQ upload limit is {maxFaqUploadLimit} FAQ's at once.
                </Typography>
                <p>
                  <a href="/csv-faq-example.csv">CSV TEMPLATE LINK</a>
                </p>
              </Box>
              <FileUploadWidget
                accept=".csv"
                className={classes.uploadBtn}
                id="csv-file-upload"
                fieldName="csv-file"
                text={
                  <Grid container justify="center" alignItems="center">
                    <Grid item>
                      <AddSharpIcon color={"inherit"}></AddSharpIcon>
                    </Grid>
                    <Grid item>
                      <Typography align="center" color={"inherit"}>
                        {"Upload csv file"}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                onChange={handleFileChange}
              />
              {(uploadMsg.success || uploadMsg.fail) && (
                <Box mt={1}>
                  <Alert
                    classes={{ icon: classes.alertIcon }}
                    onClose={handleAlertClose}
                    severity={uploadMsg.fail ? "error" : "success"}
                  >
                    {uploadMsg.success || uploadMsg.fail}
                  </Alert>
                </Box>
              )}
              {file && (
                <List className={classes.root}>
                  <ListItem className={classes.fileListItem}>
                    <ListItemAvatar>
                      <Avatar>
                        <AttachFileIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={file.name}
                      secondary={Math.ceil(file.size / 1023) + "kb"}
                    />
                    <IconButton onClick={handleFileClose}>
                      <CloseIcon color="secondary" />
                    </IconButton>
                  </ListItem>
                </List>
              )}
            </Grid>
          </Box>
          {file && (
            <Button
              onClick={loadCsvData}
              variant="outlined"
              color="primary"
              size="large"
            >
              {isCsvLoading ? "Loading FAQ ..." : "Load FAQ"}
            </Button>
          )}
        </Collapse>
      </List>
    </>
  );
}

function FAQItem(props: FAQProps) {
  const classes = useClasses();
  const faqItem = useMemo(
    () => (
      <Grid item xs={12}>
        <Paper className={classes.flowFAQ}>
          <Grid container justify="flex-end" spacing={4}>
            <Grid item>
              <Tooltip placement="left" title="">
                <IconButton
                  onClick={() => props.dispatch(removeFAQ(props.index))}
                  size="small"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <FAQTypeSelector {...props} />
            <FAQRankSelector {...props} />
            <FAQQuestion {...props} />
            <FAQAnswer {...props} />
          </Grid>
        </Paper>
      </Grid>
    ),
    [props.answer, props.question, props.type, props.originalIndex]
  );
  return <>{faqItem}</>;
}

function FAQTypeSelector({ index, dispatch, ...FAQ }: FAQProps) {
  return (
    <Grid item xs={12} sm={6}>
      <Tooltip placement="top-end" title="">
        <FormControl>
          <InputLabel>Type</InputLabel>
          <Select
            value={FAQ.type}
            onChange={(event) =>
              dispatch(setFAQType(index, event.target.value as FAQType))
            }
          >
            <MenuItem value={FAQType.Url}>
              {getFAQTypeName(FAQType.Url)}
            </MenuItem>
            <MenuItem value={FAQType.Text}>
              {getFAQTypeName(FAQType.Text)}
            </MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
    </Grid>
  );
}

function FAQRankSelector({ index, dispatch, faqs = [], ...FAQ }: FAQProps) {
  return (
    <Grid item xs={12} sm={6}>
      <Tooltip placement="top-end" title="">
        <FormControl>
          <InputLabel>Rank</InputLabel>
          <Select
            value={index}
            onChange={(event) =>
              dispatch(setFAQRank(index, event.target.value as number))
            }
          >
            {faqs.map((_, indexValue) => (
              <MenuItem key={indexValue} value={indexValue}>
                {indexValue + 1}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Tooltip>
    </Grid>
  );
}

function FAQQuestion({ index, dispatch, ...FAQ }: FAQProps) {
  return (
    <FAQRow>
      <Grid item xs={12}>
        <Tooltip placement="top-end" title="">
          <TextField
            multiline
            label="Question"
            value={FAQ.question}
            onChange={(event) =>
              dispatch(setQuestionText(index, event.target.value))
            }
          />
        </Tooltip>
      </Grid>
    </FAQRow>
  );
}

function FAQAnswer({ index, dispatch, ...FAQ }: FAQProps) {
  return (
    <FAQRow>
      <Grid item xs={12}>
        <Tooltip placement="top-end" title="">
          <TextField
            multiline
            label={getFAQTypeName(FAQ.type)}
            value={FAQ.answer}
            onChange={(event) =>
              dispatch(setAnswerText(index, event.target.value))
            }
          />
        </Tooltip>
      </Grid>
    </FAQRow>
  );
}

function FAQRow({ children }: { children: ReactNode }) {
  return (
    <Grid container item xs={12} spacing={2}>
      {children}
    </Grid>
  );
}

function useFAQs(id: string) {
  const { configuration, dispatchFAQsAction } = useConfiguration(id);
  useEffect(() => {
    dispatchFAQsAction(init());
    // eslint-disable-next-line
  }, []);

  const FAQsWithOriginalIndex = configuration?.customQnA?.map((faq, i) => ({
    ...faq,
    originalIndex: i,
  }));

  return {
    customQnA: FAQsWithOriginalIndex || [],
    dispatchFAQsAction,
  };
}

const useClasses = createUseClasses((theme) => ({
  flowFAQ: {
    padding: theme.spacing(2),
    margin: `${theme.spacing(2)}px 0`,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      margin: `${theme.spacing(1)}px 0`,
    },
  },
}));

const useCsvFaqUploadClasses = createUseClasses((theme) => ({
  listItem: {
    backgroundColor: theme.palette.background.paper,
  },
  fileListItem: {
    borderRadius: "4px",
    marginBottom: "5px",
    background: theme.palette.background.paper,
  },
  alertIcon: {
    alignItems: "center",
  },
}));
