import { createUseClasses } from "../Theme/createUseClasses";

export const useLoginCredentialsClasses = createUseClasses((theme) => ({
  input: {
    width: "50%",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
}));
