import * as React from "react";
import { createUseClasses } from "../Theme/createUseClasses";
import { Hidden, Typography } from "@material-ui/core";
import { ReactNode } from "react";

export enum TitleVariant {
  Primary = "Primary",
  Secondary = "Secondary",
}

export function Title({
  children,
  variant,
}: {
  children: ReactNode;
  variant?: TitleVariant;
}) {
  return variant === TitleVariant.Secondary ? (
    <SecondaryTitle>{children}</SecondaryTitle>
  ) : (
    <PrimaryTitle>{children}</PrimaryTitle>
  );
}

function PrimaryTitle({ children }: { children: ReactNode }) {
  const classes = useClasses();
  return (
    <Typography variant="h4" className={classes.title}>
      {children}
    </Typography>
  );
}

function SecondaryTitle({ children }: { children: ReactNode }) {
  return (
    <>
      <Hidden mdUp>
        <Typography variant="h6">{children}</Typography>
      </Hidden>
      <Hidden smDown>
        <Typography variant="h5">{children}</Typography>
      </Hidden>
    </>
  );
}

const useClasses = createUseClasses((theme) => ({
  title: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: 1,
    },
  },
}));
