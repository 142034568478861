import { Page } from "../common/Page/Page";
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  Theme,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { DynamicRoutePath } from "../Routes";
import {
  IConfiguration,
  newConfigurationId,
} from "../common/ConfigurationsContext/Configuration";
import { useConfigurations } from "../common/ConfigurationsContext/ConfigurationsContext";
import { Title } from "../common/Title/Title";
import { createUseClasses } from "../common/Theme/createUseClasses";

export function Stores() {
  const { loading, configurations } = useConfigurations();
  const filteredConfigurations =
    filterOutUnmodifiedNewConfiguration(configurations);

  return (
    <Page title={<Title>Stores</Title>}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {filteredConfigurations.length === 0 ? (
            <NoStoresDisclaimer />
          ) : (
            <StoreList configurations={filteredConfigurations} />
          )}
          <div></div>
        </>
      )}
    </Page>
  );
}

function StoreList({ configurations }: { configurations: IConfiguration[] }) {
  return (
    <List>
      {configurations.map((configuration, index) => (
        <StoreListItem key={index} configuration={configuration} />
      ))}
    </List>
  );
}

function StoreListItem({ configuration }: { configuration: IConfiguration }) {
  const history = useHistory();
  const classes = useClasses();

  return (
    <ListItem
      className={classes.storeListItem}
      button
      divider
      onClick={() =>
        history.push(
          configuration.id === newConfigurationId
            ? DynamicRoutePath.StoreConfiguration(configuration.id as string)
            : DynamicRoutePath.StoreMetrics(configuration.id as string)
        )
      }
    >
      <span>
        {configuration.storeNameFormatted || configuration.storeName}{" "}
        {configuration.id === newConfigurationId ? "(Local draft)" : ""}
      </span>
    </ListItem>
  );
}

function NoStoresDisclaimer() {
  return (
    <Box m={2}>
      <Typography>
        <p>Welcome to Upsy Dashboard!</p>
        <p>Your account has been created successfully!</p>
        <p>
          To gain access to your store metrics and settings, please email us
          (support@upsyshopping.com) your username (the email address you used
          to create the account) and request us to connect your store with your
          account. We will notify you once the store is connected with your
          account for you to access it.
        </p>
        <br />
        <p>
          If you still haven't purchased Upsy yet and would like to boost up
          your store sales, please contact sales@upsyshopping.com and we will
          contact you shortly!
        </p>
      </Typography>
    </Box>
  );
}

function filterOutUnmodifiedNewConfiguration(configurations: IConfiguration[]) {
  return configurations.filter(
    (configuration) =>
      configuration.id !== newConfigurationId ||
      (configuration.storeName && 0 < configuration.storeName.length)
  );
}

const useClasses = createUseClasses((theme: Theme) => ({
  storeListItem: {
    width: "min(100%, 200px)",
    border: "3px solid #B2C8FF",
    borderRadius: "5px",
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    marginTop: "20px",
  },
}));
