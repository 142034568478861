import { Platform } from "../../common/ConfigurationsContext/Configuration";

export interface ConfigurationWizardPageProps {
  id: string;
}

export enum ConfigurationWizardCommonPageType {
  CommonBackend = "CommonBackend",
  StoreSpecificBackend = "StoreSpecificBackend",
  CommonFrontend = "CommonFrontend",
  CommonFrontendFlowRules = "CommonFrontendFlowRules",
  Summary = "Summary",
  Success = "Success",
  CommonFrontendFAQs = "CommonFrontendFAQs",
}

export type ConfigurationWizardPageType =
  | ConfigurationWizardCommonPageType
  | Platform;

export const fullConfigurationWizardPages: ConfigurationWizardPageType[] = [
  ConfigurationWizardCommonPageType.CommonBackend,
  ConfigurationWizardCommonPageType.StoreSpecificBackend,
  ConfigurationWizardCommonPageType.CommonFrontend,
  ConfigurationWizardCommonPageType.CommonFrontendFlowRules,
  ConfigurationWizardCommonPageType.CommonFrontendFAQs,
  ConfigurationWizardCommonPageType.Summary,
  ConfigurationWizardCommonPageType.Success,
];

export const FEConfigurationWizardPages: ConfigurationWizardPageType[] = [
  ConfigurationWizardCommonPageType.CommonFrontend,
  ConfigurationWizardCommonPageType.CommonFrontendFlowRules,
  ConfigurationWizardCommonPageType.CommonFrontendFAQs,
  ConfigurationWizardCommonPageType.Summary,
  ConfigurationWizardCommonPageType.Success,
];

export const configurationWizardPagesByType = {
  [Platform.WooCommerce]: fullConfigurationWizardPages,
  [Platform.Liana]: FEConfigurationWizardPages,
  [Platform.Magento]: FEConfigurationWizardPages,
  [Platform.MyCashFlow]: FEConfigurationWizardPages,
  [Platform.GoogleShoppingXML]: FEConfigurationWizardPages,
  [Platform.Refox]: FEConfigurationWizardPages,
  [Platform.Sotka]: FEConfigurationWizardPages,
  [Platform.Shopify]: FEConfigurationWizardPages,
};
