export const writeToLocalStorageDebounced = debounce(
  (key: string, data: string) => localStorage.setItem(key, data)
);

function debounce<T extends any[]>(
  callback: (...args: T) => void,
  timeout = 100
) {
  let timer: NodeJS.Timeout;
  return (...args: T) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), timeout);
  };
}

export const readFromLocalStorage = (key: string) => localStorage.getItem(key);
