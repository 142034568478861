import { Grid } from "@material-ui/core";
import React, { ReactNode } from "react";
import { createUseClasses } from "../../common/Theme/createUseClasses";

export function ConfigurationInputGroupContainer({
  children,
  className,
  noSpacing,
}: {
  children: ReactNode;
  className?: string;
  noSpacing?: boolean;
}) {
  const classes = useClasses();
  return (
    <Grid className={classes.container} container>
      <Grid
        container
        item
        spacing={noSpacing ? undefined : 2}
        className={className}
        xs={12}
        md={9}
        lg={6}
      >
        {children}
      </Grid>
    </Grid>
  );
}

export function ConfigurationInputContainer({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <Grid item xs={12} md={9} lg={6} className={className}>
      {children}
    </Grid>
  );
}

const useClasses = createUseClasses(() => ({
  container: {
    marginTop: "30px",
  },
}));
