import {
  AppBar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Toolbar,
} from "@material-ui/core";
import { StaticRoutePath } from "../../Routes";
import { AccountCircle, Store } from "@material-ui/icons";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { createUseClasses } from "../Theme/createUseClasses";
import { Title } from "../Title/Title";
import { LogoUpsyShoppingHelper } from "../SVG/Component/LogoUpsyShoppingHelper";

export function DesktopNavigation() {
  const classes = useDesktopClasses();

  return (
    <List>
      <ListItem className={classes.logo}>
        <LogoUpsyShoppingHelper variant="blue" width="50%" />
      </ListItem>
      <ListItem
        button
        component={NavLink}
        to={StaticRoutePath.Stores}
        activeClassName={classes.active}
      >
        <ListItemIcon>
          <Store />
        </ListItemIcon>
        <ListItemText primary="Stores" />
      </ListItem>
      <ListItem
        button
        component={NavLink}
        to={StaticRoutePath.Account}
        activeClassName={classes.active}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        <ListItemText primary="Account" />
      </ListItem>
    </List>
  );
}

export function MobileNavigation() {
  const classes = useMobileClasses();

  return (
    <AppBar>
      <Toolbar>
        <Grid container justify="space-between">
          <Grid item container alignItems="center" xs={6}>
            <Title>upsy</Title>
            <IconButton
              component={NavLink}
              to={StaticRoutePath.Stores}
              activeClassName={classes.active}
            >
              <Store className={classes.appBarItem} />
            </IconButton>
          </Grid>
          <IconButton
            edge="end"
            component={NavLink}
            to={StaticRoutePath.Account}
            activeClassName={classes.active}
          >
            <AccountCircle className={classes.appBarItem} />
          </IconButton>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

const useDesktopClasses = createUseClasses((theme: Theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  logo: {
    justifyContent: "center",
    paddingBottom: "16px",
  },
  active: {
    background: "rgba(237, 239, 245,1)",
  },
}));

const useMobileClasses = createUseClasses((theme: Theme) => ({
  appBarItem: {
    color: theme.palette.background.paper,
  },
  active: {
    background: "rgba(234, 233, 233, 0.19)",
    "&:nth-of-type(1)": {
      marginLeft: "3px",
    },
  },
}));
