import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import { getEndDateFilter, getStartDateFilter } from "./dateUtils";

interface DateRange {
  start: Date;
  end: Date;
}

export interface QuicksightEmbedDateParameters {
  dateRange: DateRange;
  comparisonDateRange: DateRange;
}

export interface QuicksightEmbedParameters
  extends QuicksightEmbedDateParameters {
  tenantId: string;
}

export const embedQuicksight = (
  container: HTMLIFrameElement,
  url: string,
  parameters: QuicksightEmbedParameters,
  setLoaded: (loaded: true) => void
) => {
  return embedDashboard({
    url,
    container,
    parameters: parseParameters(parameters),
    loadCallback: () => setLoaded(true),
    height: "3000px",
  });
};

export function setQuicksightParameters(
  dashboard: any,
  parameters: QuicksightEmbedParameters
) {
  try {
    dashboard.setParameters(parseParameters(parameters));
  } catch (error) {
    console.error(error);
  }
}

const formatTenantId = (tenantId: string): string => {
  const noPrefix = tenantId.replace(/^(\s*ups-)/, "");
  return `${noPrefix.charAt(0).toUpperCase()}${noPrefix.slice(1)}`;
};

function parseParameters(parameters: QuicksightEmbedParameters) {
  const { dateRange, comparisonDateRange, tenantId } = parameters;
  return {
    datefrom: getStartDateFilter(dateRange.start),
    dateto: getEndDateFilter(dateRange.end),
    datefromcomparison: getStartDateFilter(comparisonDateRange.start),
    datetocomparison: getEndDateFilter(comparisonDateRange.end),
    customer: formatTenantId(tenantId),
  };
}
