import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@material-ui/core";
import * as React from "react";
import { ReactNode, useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useLoginCredentialsClasses } from "./UseLoginCredentialsClasses";
import ApiError from "../Client/ApiError";
import { CreateUserApiError } from "../Client/BackendResponse";

type LoginCredentialsProps = {
  submitButtonTitle: string;
  onSubmit: (username: string, password: string) => void;
  beforeContent?: ReactNode;
};

export function LoginCredentials({
  submitButtonTitle,
  onSubmit,
  beforeContent,
}: LoginCredentialsProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [submitError, setSubmitError] = useState<undefined | string>();
  const [submitErrorApiError, setSubmitErrorApiError] =
    useState<CreateUserApiError>({});
  const classes = useLoginCredentialsClasses();

  async function submit() {
    if (!isValidEmail(email) || !isValidPassword(password)) {
      setShowValidationErrors(true);
      return;
    }
    try {
      await onSubmit(email, password);
    } catch (error) {
      if (error instanceof ApiError && error.jsonBody) {
        setSubmitErrorApiError(error.jsonBody);
      } else {
        setSubmitError(error.message);
      }
    }
  }

  const hasEmailError =
    Boolean(submitError) ||
    Boolean(submitErrorApiError.user) ||
    (showValidationErrors && !isValidEmail(email));
  const hasPasswordError =
    Boolean(submitError) ||
    Boolean(submitErrorApiError.password) ||
    (showValidationErrors && !isValidPassword(password));

  return (
    <Grid container spacing={2}>
      {beforeContent}
      <Grid item xs={12}>
        <TextField
          error={hasEmailError}
          className={classes.input}
          label="Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setSubmitError(undefined);
          }}
          helperText={submitError || submitErrorApiError.user}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.input}>
          <InputLabel error={hasPasswordError}>Password</InputLabel>
          <Input
            error={hasPasswordError}
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setSubmitError(undefined);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText error={hasPasswordError} id="password-error-text">
            {submitError || submitErrorApiError.password}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={submit}>
          {submitButtonTitle}
        </Button>
      </Grid>
    </Grid>
  );
}

function isValidEmail(email: string) {
  const emailUsernameAndDomain = email.split("@");
  return (
    emailUsernameAndDomain.length === 2 &&
    emailUsernameAndDomain[0].length &&
    emailUsernameAndDomain[1].length
  );
}

function isValidPassword(password: string) {
  return password.length;
}
