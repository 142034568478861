import { Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { createUseClasses } from "../Theme/createUseClasses";

export type OnFileChangeEventType = (
  e: React.ChangeEvent<HTMLInputElement>
) => void;

export interface fileUploadWidgetInterface {
  id: string;
  fieldName: string;
  text: React.ReactElement | string;
  multiple?: boolean;
  className?: string;
  accept?: string;
  onChange: OnFileChangeEventType;
}

export default function FileUploadWidget({
  onChange,
  id,
  fieldName,
  text,
  multiple = false,
  accept,
  className,
}: fileUploadWidgetInterface) {
  const classes = useClasses();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Button
          className={`${classes.root} ${className}`}
          component="label"
          variant="text"
          htmlFor={id}
          fullWidth
        >
          {text}
          <input
            accept={accept}
            type="file"
            name={fieldName}
            id={id}
            hidden
            onChange={onChange}
          />
        </Button>
      </Grid>
    </Grid>
  );
}

const useClasses = createUseClasses((theme) => ({
  root: {
    border: "2px dashed",
    borderColor: theme.palette.primary.main,
  },
}));
