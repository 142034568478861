import * as React from "react";
import { Page } from "../common/Page/Page";
import { ReactNode } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DynamicRoutePath, IStoreRouteParams } from "../Routes";
import { useConfiguration } from "../common/ConfigurationsContext/ConfigurationsContext";
import { Tab, Tabs } from "@material-ui/core";
import { newConfigurationId } from "../common/ConfigurationsContext/Configuration";
import { createUseClasses } from "../common/Theme/createUseClasses";
import { Title } from "../common/Title/Title";

export function Store({ children }: { children: ReactNode }) {
  const { id } = useParams<IStoreRouteParams>();
  const { configuration } = useConfiguration(id);
  const history = useHistory();
  const classes = useClasses();

  return (
    <Page
      title={
        <Title>
          {id === newConfigurationId
            ? "New Configuration"
            : configuration
            ? configuration.storeNameFormatted
            : "Error: Could not find store"}
        </Title>
      }
      internalNavigation={
        id !== newConfigurationId && (
          <Tabs value={history.location.pathname} className={classes.tabs}>
            <Tab
              label="Metrics"
              className={classes.tab}
              value={DynamicRoutePath.StoreMetrics(id)}
              onClick={() => history.push(DynamicRoutePath.StoreMetrics(id))}
            />
            <Tab
              label="Configuration"
              className={classes.tab}
              value={DynamicRoutePath.StoreConfiguration(id)}
              onClick={() =>
                history.push(DynamicRoutePath.StoreConfiguration(id))
              }
              // disabled={configuration?.platform !== Platform.WooCommerce}
            />
            <Tab
              label="Support"
              className={classes.tab}
              value={DynamicRoutePath.StoreDashboard(id)}
              onClick={() => history.push(DynamicRoutePath.StoreDashboard(id))}
            />
          </Tabs>
        )
      }
    >
      {children}
    </Page>
  );
}

const useClasses = createUseClasses((theme) => ({
  tabs: {
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
}));
