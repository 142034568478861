class ApiError extends Error {
  jsonBody: Object | undefined;

  constructor(message: string | Object) {
    const isObject = typeof message === "object";
    super(isObject ? JSON.stringify(message) : "Error");
    this.name = "ApiError";
    this.jsonBody = isObject ? message : undefined;
  }
}

export default ApiError;
