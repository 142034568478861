import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import {
  defaultAvatar,
  defaultButtonColor,
  getWooCommerceVersion,
  IConfigurationCommonFrontend,
  IWooCommerceConfiguration,
  Platform,
} from "../../common/ConfigurationsContext/Configuration";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ReactNode, useEffect, useState } from "react";
import { ConfigurationWizardPageProps } from "./configurationWizardPage";
import {
  FlowRuleType,
  IFlowRule,
  IFlowRuleButton,
  isCommonButton,
} from "./flowRules";
import { Title, TitleVariant } from "../../common/Title/Title";
import { UpsyPreview } from "./UpsyPreview";
import { LoadingButton } from "../../common/LoadingButton/LoadingButton";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import { getFAQTypeName, IFAQ } from "./FAQs";
import { ExpandMore, ArrowRight } from "@material-ui/icons";

export function ConfigurationSummary({ id }: ConfigurationWizardPageProps) {
  const { configuration } = useConfiguration(id);
  const {
    platform,
    storeName,
    storeNameFormatted,
    storeUrl,
    apiVersion,
    key,
    secret,
    operationMode,
    language,
    avatar,
    buttonColor,
    flows,
    customQnA,
  } = configuration as IWooCommerceConfiguration;

  const classes = useClasses();

  return (
    <>
      <Title variant={TitleVariant.Secondary}>Summary</Title>
      <Grid container justify="flex-end">
        <SummaryRow label="Platform" value={platform} />
        <SummaryRow
          label="Store name"
          value={storeNameFormatted || storeName}
        />
        <SummaryRow label="Store URL" value={storeUrl} />
        {platform === Platform.WooCommerce && (
          <>
            <SummaryRow
              label="WooCommerce version"
              value={apiVersion ? getWooCommerceVersion(apiVersion) : ""}
            />
            <SummaryRow label="WooCommerce key" value={key} />
            <SummaryRow label="WooCommerce secret" value={secret} />
          </>
        )}
        <SummaryRow label="Operation mode" value={operationMode} />
        <SummaryRow label="Language" value={language} />
        <SummaryRow label="Avatar" value={avatar} />
        <SummaryRow label="Primary color" value={buttonColor} />
        <br />
        <SummaryFlowRules
          flows={flows as IFlowRule[]}
          buttonColor={buttonColor || defaultButtonColor}
          avatar={avatar || defaultAvatar}
          classes={classes.summaryRow}
        />
        <SummaryFAQs
          customQnA={customQnA as IFAQ[]}
          classes={classes.summaryRow}
        />
      </Grid>
    </>
  );
}

function SummaryRow({ label, value }: { label: ReactNode; value: ReactNode }) {
  return (
    <SummaryLabelAndValue
      label={label}
      value={<Typography variant="body2">{value}</Typography>}
    />
  );
}

function SummaryLabelAndValue({
  label,
  value,
  classes,
}: {
  label: ReactNode;
  value: ReactNode;
  classes?: string;
}) {
  return (
    <>
      <Grid item xs={12} md={3} className={classes}>
        <Typography variant="subtitle2">{label}:</Typography>{" "}
      </Grid>
      <Grid item xs={11} md={9} className={classes}>
        {value}
      </Grid>
    </>
  );
}

function SummaryFlowRules({
  flows,
  avatar,
  buttonColor,
  classes,
}: { flows: IFlowRule[]; classes: string } & Pick<
  IConfigurationCommonFrontend,
  "avatar" | "buttonColor"
>) {
  const { previewingIndex, previewProgress, preview, buttonTexts } =
    useUpsyButtonsPreview();
  const accordionClasses = useAccordionClasses();

  return (
    <>
      <Grid container justify="flex-end">
        <Grid item xs={12}>
          <Accordion className={accordionClasses.root}>
            <AccordionSummary
              expandIcon={<ExpandMore></ExpandMore>}
              classes={{ root: accordionClasses.summaryRoot }}
            >
              <Typography
                variant="body1"
                className={accordionClasses.typography}
              >
                Flow rules summary
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer style={{ maxHeight: 480 }}>
                <Table aria-label="simple table 2" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left"> Selector Type </TableCell>
                      <TableCell align="left"> Selector Value </TableCell>
                      <TableCell align="left"> Popup </TableCell>
                      <TableCell>Buttons</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(flows || []).map((flow, index) => (
                      <TableRow key={flow.trigger.value}>
                        <TableCell
                          style={{ verticalAlign: "top" }}
                          align="left"
                          component="th"
                          scope="row"
                        >
                          {flow.trigger.type}
                        </TableCell>
                        <TableCell
                          style={{ verticalAlign: "top" }}
                          align="left"
                        >
                          {flow.trigger.value}
                          <br />
                          {flow.trigger.isPurchase ? "(thank you page)" : ""}
                        </TableCell>
                        <TableCell
                          style={{ verticalAlign: "top" }}
                          align="left"
                        >
                          {flow.buttons.initial.length
                            ? `${flow.buttons.initial[0].text}`
                            : "No popup"}
                        </TableCell>
                        <TableCell>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Button Type</TableCell>
                                  <TableCell>Button Label</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {flow.buttons.expanded.map((button) => (
                                  <TableRow>
                                    <TableCell>{button.type}</TableCell>
                                    <TableCell>
                                      {isCommonButton(button)
                                        ? button.text
                                        : ""}
                                    </TableCell>
                                  </TableRow>
                                ))}
                                <TableRow>
                                  <TableCell
                                    classes={{
                                      root: accordionClasses.noBorderBottom,
                                    }}
                                    colSpan={4}
                                    align="right"
                                  >
                                    <LoadingButton
                                      variant="contained"
                                      buttonText="Preview"
                                      loadingText="Previewing..."
                                      loading={index === previewingIndex}
                                      progress={previewProgress}
                                      classes={{
                                        root: accordionClasses.previewBtnRoot,
                                      }}
                                      onClick={() =>
                                        preview(flow.buttons.expanded, index)
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {0 < buttonTexts.length && (
                <Grid container alignItems="flex-end">
                  <UpsyPreview
                    style={{ zIndex: 999, height: "100%" }}
                    avatar={avatar}
                    buttonColor={buttonColor}
                    buttonTexts={buttonTexts}
                  />
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
}
function SummaryFAQs({
  customQnA,
  classes,
}: {
  customQnA: IFAQ[];
  classes: string;
}) {
  const accordionClasses = useAccordionClasses();
  return (
    <Grid container justify="flex-end">
      <Grid item xs={12}>
        <Accordion className={accordionClasses.root}>
          <AccordionSummary
            expandIcon={<ExpandMore></ExpandMore>}
            classes={{ root: accordionClasses.summaryRoot }}
          >
            <Typography variant="body1" className={accordionClasses.typography}>
              Faqs configurations summary
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer style={{ maxHeight: 480 }}>
              <Table aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">FAQ Type</TableCell>
                    <TableCell align="right">Question</TableCell>
                    <TableCell align="left">Answer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(customQnA || []).map((row) => (
                    <TableRow key={row.question}>
                      <TableCell
                        style={{ verticalAlign: "top" }}
                        align="left"
                        component="th"
                        scope="row"
                      >
                        {row.type}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }} align="right">
                        {row.question}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }} align="left">
                        {row.answer}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}

function useUpsyButtonsPreview() {
  const [buttonTexts, setButtonTexts] = useState<string[]>([]);
  const [previewingIndex, setPreviewingIndex] = useState<undefined | number>();
  const [previewProgress, setPreviewProgress] = useState(0);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (previewingIndex !== undefined && previewProgress < 100) {
      timeout = setTimeout(() => {
        setPreviewProgress(previewProgress + 1);
      }, 50);
    } else {
      setButtonTexts([]);
      setPreviewingIndex(undefined);
      setPreviewProgress(0);
    }

    return () => clearTimeout(timeout);
  }, [previewingIndex, previewProgress]);

  return {
    previewingIndex,
    previewProgress,
    buttonTexts,
    preview: (buttons: IFlowRuleButton[], index: number) => {
      setButtonTexts(
        buttons.map((button) =>
          isCommonButton(button) ? button.text : "Category button(s)"
        )
      );
      setPreviewingIndex(index);
      setPreviewProgress(0);
    },
  };
}

const useClasses = createUseClasses((theme) => ({
  summaryRow: {
    marginTop: "2em",
  },
}));

const useAccordionClasses = createUseClasses((theme) => ({
  root: {
    background: "#fff",
    marginTop: 10,
    marginBottom: 10,
    boxShadow: "none",
  },
  summaryRoot: {
    background: "rgb(246,249,254)",
    borderRadius: "0.5rem",
  },
  typography: {
    fontWeight: 500,
    fontSize: "1.1rem",
    textAlign: "left",
  },
  previewBtnRoot: {
    boxShadow: "none",
    color: "#876BFD",
    background: "rgb(246,249,254)",
    "&:hover": {
      background: "#e8ebf0",
      boxShadow: "none",
    },
  },
  noBorderBottom: {
    borderBottom: 0,
  },
}));
