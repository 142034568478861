import React from "react";
import { UserContextProvider } from "./common/UserContext/UserContext";
import { HashRouter } from "react-router-dom";
import { Routes } from "./Routes";
import { Theme } from "./common/Theme/Theme";
import { ConfigurationsContextProvider } from "./common/ConfigurationsContext/ConfigurationsContext";

function App() {
  return (
    <Theme>
      <UserContextProvider>
        <ConfigurationsContextProvider>
          <HashRouter>
            <Routes />
          </HashRouter>
        </ConfigurationsContextProvider>
      </UserContextProvider>
    </Theme>
  );
}

export default App;
