import { ConfigurationWizardPageProps } from "./configurationWizardPage";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SettingsIcon from "@material-ui/icons/Settings";
import { Dispatch, ReactNode, useEffect, useState } from "react";
import {
  addButton,
  addRule,
  enablePurchase,
  FlowRulesAction,
  init,
  removeButton,
  removeRule,
  setButtonText,
  setButtonUrl,
  setButtonType,
  setPopupText,
  setTriggerType,
  setTriggerValue,
} from "./flowRulesReducer";
import { IFlowRuleButtonUrl, PopupOptionType } from "./flowRules";
import { createUseClasses } from "../../common/Theme/createUseClasses";
import {
  categoriesUIPopupOption,
  urlUIPopupOption,
  FlowRuleType,
  getRuleTypeName,
  IFlowRule,
  UIPopupOptionType,
} from "./flowRules";
import { useConfiguration } from "../../common/ConfigurationsContext/ConfigurationsContext";
import { ConfigurationInputGroupContainer } from "./ConfigurationInputContainer";
import { Title, TitleVariant } from "../../common/Title/Title";

export function CommonFrontendFlowRules({ id }: ConfigurationWizardPageProps) {
  const { dispatchFlowRulesAction, flows } = useFlows(id);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const handleCollapsable = () => {
    setCollapseOpen(!collapseOpen);
  };
  const classes = useFlowRulesClasses();

  return (
    <>
      <List
        component="nav"
        aria-labelledby="collapseable-list-subheader"
        subheader={
          <ListSubheader
            disableSticky
            component="div"
            id="collapseable-list-subheader"
          >
            Advanced settings
          </ListSubheader>
        }
      >
        <Grid container>
          <Grid item xs={12} md={9} lg={6}>
            <ListItem
              className={classes.listItem}
              alignItems="center"
              button
              onClick={handleCollapsable}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography align="left" variant="body1">
                    Flow Rules
                  </Typography>
                }
              />
              {collapseOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </Grid>
        </Grid>
        <Collapse
          in={collapseOpen}
          timeout={{ enter: 380, exit: 360 }}
          unmountOnExit
        >
          <Box mt={1.75} mb={-4}>
            <Title variant={TitleVariant.Secondary}>Flow Rules</Title>
          </Box>
          <ConfigurationInputGroupContainer noSpacing>
            {flows.map((rule, index) => (
              <FlowRule
                {...rule}
                key={index}
                index={index}
                dispatch={dispatchFlowRulesAction}
              />
            ))}
          </ConfigurationInputGroupContainer>
          <div>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => dispatchFlowRulesAction(addRule())}
            >
              Add rule
            </Button>
          </div>
        </Collapse>
      </List>
    </>
  );
}

type FlowRuleProps = {
  index: number;
  dispatch: Dispatch<FlowRulesAction>;
} & IFlowRule;

function FlowRule(props: FlowRuleProps) {
  const classes = useClasses();
  const {
    trigger: { type: flowType },
  } = props;

  return flowType === FlowRuleType.HideOnUrl ? (
    <Grid item>
      <Paper className={classes.flowRule}>
        <Grid container spacing={0}>
          <FlowRuleTitleAndRemove {...props} />
          <FlowRuleSelector {...props} />
        </Grid>
      </Paper>
    </Grid>
  ) : (
    <Grid item>
      <Paper className={classes.flowRule}>
        <Grid container spacing={2}>
          <FlowRuleTitleAndRemove {...props} />
          <FlowRuleSelector {...props} />
          <FlowRulePopup {...props} />
          <FlowRuleButtons {...props} />
          <FlowRuleThankYouPage {...props} />
        </Grid>
      </Paper>
    </Grid>
  );
}

function FlowRuleTitleAndRemove({ index, dispatch, ...rule }: FlowRuleProps) {
  return (
    <Grid container item xs={12} justify="center">
      <Grid item xs={8}>
        <Typography variant="subtitle2">Rule {index + 1}</Typography>
      </Grid>
      <Grid container item xs={4} justify="flex-end">
        <Tooltip placement="left" title="">
          <IconButton onClick={() => dispatch(removeRule(index))} size="small">
            <Delete />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

function FlowRuleSelector({ index, dispatch, ...rule }: FlowRuleProps) {
  return (
    <FlowRuleRow>
      <Grid item xs={12} sm={6}>
        <Tooltip placement="top-end" title="">
          <FormControl>
            <InputLabel>Type</InputLabel>
            <Select
              value={rule.trigger.type}
              onChange={(event) =>
                dispatch(
                  setTriggerType(index, event.target.value as FlowRuleType)
                )
              }
            >
              <MenuItem value={FlowRuleType.Url}>
                {getRuleTypeName(FlowRuleType.Url)}
              </MenuItem>
              <MenuItem value={FlowRuleType.ClassSelector}>
                {getRuleTypeName(FlowRuleType.ClassSelector)}
              </MenuItem>
              <MenuItem value={FlowRuleType.UpsySelector}>
                {getRuleTypeName(FlowRuleType.UpsySelector)}
              </MenuItem>
              <MenuItem value={FlowRuleType.HideOnUrl}>
                {getRuleTypeName(FlowRuleType.HideOnUrl)}
              </MenuItem>
            </Select>
          </FormControl>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip placement="top-end" title="">
          <TextField
            label={getRuleTypeName(rule.trigger.type)}
            value={rule.trigger.value}
            onChange={(event) =>
              dispatch(setTriggerValue(index, event.target.value))
            }
          />
        </Tooltip>
      </Grid>
    </FlowRuleRow>
  );
}

function FlowRulePopup({ index, dispatch, ...rule }: FlowRuleProps) {
  return (
    <FlowRuleRow>
      <Grid item xs={12}>
        <Tooltip placement="top-end" title="">
          <TextField
            multiline
            label="Popup text"
            value={rule.buttons.initial[0].text}
            onChange={(event) =>
              dispatch(setPopupText(index, event.target.value))
            }
          />
        </Tooltip>
      </Grid>
    </FlowRuleRow>
  );
}

function FlowRuleButtons(props: FlowRuleProps) {
  const {
    buttons: { expanded: buttons },
    dispatch,
    index,
  } = props;
  const buttonArray = buttons.map((button) =>
    button.type === categoriesUIPopupOption
      ? { ...button, text: categoriesUIPopupOption }
      : button
  );
  return (
    <FlowRuleRow>
      <Grid item xs={12}>
        <Typography variant="subtitle2">Buttons</Typography>
      </Grid>

      {buttonArray.map((button, index) => (
        <FlowRuleButton
          key={index}
          buttonIndex={index}
          {...props}
          {...button}
          url={(button as IFlowRuleButtonUrl)?.url || ""}
        />
      ))}
      <Grid item xs={12}>
        <Button variant="outlined" onClick={() => dispatch(addButton(index))}>
          Add Button
        </Button>
      </Grid>
    </FlowRuleRow>
  );
}

interface FlowRuleButtonProps
  extends Pick<FlowRuleProps, "index" | "dispatch"> {
  type: UIPopupOptionType;
  text: string;
  url: string;
  buttonIndex: number;
}

function FlowRuleButton({
  index,
  buttonIndex,
  type,
  text,
  url,
  dispatch,
}: FlowRuleButtonProps) {
  return (
    <>
      <Grid container item spacing={4} style={{ position: "relative" }}>
        <Grid item xs={12} sm={6}>
          <Tooltip placement="top-end" title="">
            <FormControl>
              <InputLabel>Type</InputLabel>
              <Select
                autoWidth
                value={type}
                onChange={(event) =>
                  dispatch(
                    setButtonType(
                      index,
                      event.target.value as UIPopupOptionType,
                      buttonIndex
                    )
                  )
                }
              >
                <MenuItem value={PopupOptionType.addToCart}>
                  {PopupOptionType.addToCart}
                </MenuItem>
                <MenuItem value={categoriesUIPopupOption}>
                  {categoriesUIPopupOption}
                </MenuItem>
                <MenuItem value={urlUIPopupOption}>{urlUIPopupOption}</MenuItem>
                <MenuItem value={PopupOptionType.close}>
                  {PopupOptionType.close}
                </MenuItem>
                <MenuItem value={PopupOptionType.faq}>
                  {PopupOptionType.faq}
                </MenuItem>
                <MenuItem value={PopupOptionType.productFaq}>
                  {PopupOptionType.productFaq}
                </MenuItem>
                <MenuItem value={PopupOptionType.relevantProducts}>
                  {PopupOptionType.relevantProducts}
                </MenuItem>
                <MenuItem value={PopupOptionType.searchResults}>
                  {PopupOptionType.searchResults}
                </MenuItem>
              </Select>
            </FormControl>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Tooltip placement="top-end" title="">
            <TextField
              label="Button label"
              disabled={type === categoriesUIPopupOption}
              value={type === categoriesUIPopupOption ? type : text}
              onChange={(event) =>
                dispatch(setButtonText(index, event.target.value, buttonIndex))
              }
            />
          </Tooltip>
        </Grid>
        {type === urlUIPopupOption ? (
          <Grid item xs={12}>
            <Tooltip placement="top-end" title="">
              <TextField
                label="Button URL"
                value={url}
                onChange={(event) =>
                  dispatch(setButtonUrl(index, event.target.value, buttonIndex))
                }
              />
            </Tooltip>
          </Grid>
        ) : (
          ""
        )}
        <Tooltip
          placement="top-end"
          title=""
          style={{ position: "absolute", top: 16, right: "0" }}
        >
          <IconButton
            onClick={() => dispatch(removeButton(index, buttonIndex))}
            size="small"
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Grid>
    </>
  );
}

function FlowRuleThankYouPage({ index, dispatch, ...rule }: FlowRuleProps) {
  const {
    trigger: { isPurchase },
  } = rule;
  return (
    <FlowRuleRow>
      <Grid item>
        <Tooltip placement="top-end" title="">
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(isPurchase)}
                onChange={(event) =>
                  dispatch(enablePurchase(index, event.target.checked))
                }
              />
            }
            label="Thank you page"
          />
        </Tooltip>
      </Grid>
    </FlowRuleRow>
  );
}

function FlowRuleRow({ children }: { children: ReactNode }) {
  return (
    <Grid container item xs={12} spacing={4}>
      {children}
    </Grid>
  );
}

function useFlows(id: string) {
  const { configuration, dispatchFlowRulesAction } = useConfiguration(id);
  useEffect(() => {
    dispatchFlowRulesAction(init());
    // eslint-disable-next-line
  }, []);

  return {
    flows: (configuration && configuration.flows) || [],
    dispatchFlowRulesAction,
  };
}

const useClasses = createUseClasses((theme) => ({
  flowRule: {
    padding: theme.spacing(2),
    margin: `${theme.spacing(2)}px 0`,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      margin: `${theme.spacing(1)}px 0`,
    },
  },
}));

const useFlowRulesClasses = createUseClasses((theme) => ({
  listItem: {
    backgroundColor: theme.palette.background.paper,
  },
}));
